<template>
  <div class="top">


  <div class="accordion" id="accordionExample">

    <div class="accordion-item">
      <h2 class="accordion-header">
        <button @click="loadUsers" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Users
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <h1>Users (no Company)</h1>
            <div v-for="(user,i) in data.users" :key="i">[{{user.id}}] {{user.email}}</div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header">
        <button @click="loadCompanies" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Companies
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            Changement d'entreprise :
            <b-form-select v-model="comp_selected" :options="comp_options"></b-form-select>
            <b-button variant="danger" @click="companyChange">change !</b-button>
        <div class="accordion-body">
            <vue-good-table
                  max-height="500px"
                  :columns="t_company.columns"
                  :rows="t_company.rows"
                  :search-options="{
                    enabled: false,
                  }"
                  :pagination-options="{
                    enabled: false,
                    perPage: 20,
                  }"
                  :select-options="{
                          enabled: true,
                          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                          selectionInfoClass: 'custom-class',
                          selectionText: 'rows selected',
                          clearSelectionText: 'clear',
                          disableSelectInfo: true, // disable the select info panel on top
                          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                          }"
                  @on-cell-click="onCellClick"
                  @on-selected-rows-change="onRowClick"
                  styleClass="vgt-table striped bordered">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'users'">
                            <div v-for="(x,i) in props.row.users" :key="i" class="users">
                                {{x.email}}<br/>role {{x.role}}
                            </div>
                        </span>
                        <span v-else-if="props.column.field == 'sites'">
                            <div v-for="(site,i) in props.row.sites" :key="i" class="site">
                                [{{site.id}}] {{site.adresse_complete}}
                                <div class="indicator" v-for="(indic,ii) in site.indicators" :key="ii">
                                    '{{indic.name}}', {{indic.accuracy}}%, MaJ {{indic.maj}}, dernière date: {{indic.last_date}}, nval: {{indic.nval}} [{{indic.id}}]
                                </div>
                            </div>
                        </span>
                        <span v-else-if="props.column.field == 'market'" >
                            <div v-for="(site,i) in props.row.sites" :key="i" class="site">
                                <span v-if="site.docs" class="market">
                                Marketing : <a :href="'https://docs.google.com/spreadsheets/d/'+site.docs+'/edit#gid=0'" target="docs">document</a>,
                                                                                                                           <a :href="URL+'/marketing/iframe/'+site.docs" target="docs">slideshow</a>
                                </span>
                            </div>

                        </span>
                        <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
            </vue-good-table>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header">
        <button @click="loadEvents" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Events
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <vue-good-table
                  max-height="500px"
                  :columns="t_events.columns"
                  :rows="t_events.rows"
                  :search-options="{
                    enabled: false,
                  }"
                  :pagination-options="{
                    enabled: false,
                    perPage: 20,
                  }"
                  :select-options="{
                          enabled: true,
                          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                          selectionInfoClass: 'custom-class',
                          selectionText: 'rows selected',
                          clearSelectionText: 'clear',
                          disableSelectInfo: true, // disable the select info panel on top
                          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                          }"
                  @on-cell-click="onCellClick"
                  @on-selected-rows-change="onRowClick"
                  styleClass="vgt-table striped bordered">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'events'">
                            <div v-for="(e,i) in props.row.events" :key="i" class="event">
                                {{e.start_date}} au {{e.end_date}} [{{e.id}}]
                            </div>
                        </span>
                        <span v-else>{{props.formattedRow[props.column.field]}}</span>
                        <!--<span>{{props.column.field}}
                            <b-form-input v-model="props.row[props.column.field]"
                                          type="text"
                            ></b-form-input>
                        </span>-->
                    </template>
            </vue-good-table>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<style scoped>
  .indicator {color: blue; font-size: 80%}
  .event {color: cornflowerblue; font-size: 80%}
  .top {margin-top: 73px;}
  .site {margin-left: 30px;}
  .market {color: red; display: inline; background: yellow; }
  .users {color: black; display: inline; background: lightgreen; }
        .role1 {color: red;}
        .role2 {color: gray;}

</style>

<script>
import LoginDS from "../services/LoginDS";
import AdminDS from "../services/AdminDS";
import AppliDS from "../services/AppliDS";

export default {
  data(){
    return {
        data: {},
        comp_selected: null,
        comp_options: [{text:"-sans-", value:null}],
        t_company: {
            columns: [
            {
              label: 'Id',
              field: 'id',
            },
            {
              label: 'Name',
              field: 'nom_complet',
              filterOptions: {
                enabled: true,
              },
            },
            {
              label: 'Users',
              field: 'users',
              //type: 'number',
            },
            {
              label: 'Sites',
              field: 'sites',
            },
            {
              label: 'Market',
              field: 'market',
            },
          ],
          rows: [],
        },
        t_events: {
            columns: [
            {
              label: 'Id',
              field: 'id',
            },
            {
              label: 'Name',
              field: 'name',
              filterOptions: {
                enabled: true,
              },
            },
            {
              label: 'Country',
              field: 'country',
              //type: 'number',
            },
            {
              label: 'INSEE',
              field: 'insee',
            },
            {
              label: 'Dates',
              field: 'events',
            },
          ],
          rows: [],
        },
      };
    },

  name: 'AdminView',
  components: {
  },
  methods: {
    loadUsers() {
        AdminDS.getUsers()
            .then((res) => {
                this.data.users=res.data;
                this.$emit('alert', 'primary', 'Récup '+res.data.length+' users');
            })
    },
    loadCompanies() {
        AdminDS.getCompanies()
            .then((res) => {
                this.t_company.rows = res.data;
                // Pour select changement company
                res.data.map(c=>{
                    this.comp_options.push({text: c.nom_complet+" ["+c.id+"]", value: c.id})
                });
                this.$emit('alert', 'primary', 'Récup '+res.data.length+' companies');
            });
    },
    loadEvents() {
        AdminDS.getEvents()
            .then((res) => {
                this.t_events.rows = res.data;
                this.$emit('alert', 'primary', 'Récup '+res.data.length+' évènements');
            });
    },
    onRowClick(params) {
        console.log('onRowClick', params)
    },
    onCellClick(params) {
        console.log('onCellClick', params)
    },
    companyChange() {
        LoginDS.update({company: this.comp_selected})
    },
  },
  created() {
      AppliDS.getCompany().then(res => this.comp_selected=res.data.id);
      this.loadUsers();
  },
};
</script>
