import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    // ...
    'fr': {
      indicator: "indicateur",
      company: "Entreprise",
      bizzoserv: "Bizzo à votre service",
      welcome: "Bonjour",
      evtgrp: "groupes d'évènements",
      save: "Enregistrer",
      cancel: "Annuler",
      saveddata: "Données enregistrées",
      events: "Evènements",
      meteo: "Meteo",
      rain: "Pluie",

      devise: 'Bizzo, la météo de votre Business !',
      devise2: "Anticipez l'avenir de votre bizness",
      devise3 : "Avec Bizzo, mettez l'intelligence artificielle au service de votre commerce.",
      devise4: "Activité, indicateurs, CA : obtenez des prévisions précises :",
      startUse: "Commencez à l'utiliser",
      learnMore: "En savoir plus",
      bonus: "Bonus",
      register: "Inscrivez-vous",

      about: "Comment fonctionne BizzO ?",
      about1: "L'activité de votre entreprise dépend de nombreux paramètres",
      about1_1: "La météo",
      about1_2: "Le jour de la semaine, du mois",
      about1_3: "Des vacances scolaires, en France ou à l'étranger",
      about1_4: "Des périodes de soldes",
      about1_5: "Des évènements locaux",
      about1_6: "autres",
      about2: "Tous ces paramètres sont prévisibles !",
      about2_1: 'Il est donc possible de prévoir vos indicateurs grâce à l\'utilisation de prédictions (du "Machine Learning")',
      about2_2: "Plus vous fournissez l'historique de vos indicateurs, plus la prédiction est précise",
      about3: "BizzO utilise aussi les informations de connections Wifi pour prédire la fréquentation des gens sur la région Centre Val-de Loire.",

      work: "Comment obtenir vos prévisons et mieux gérer votre activité <strong>rien de plus simple !</strong>",
      work1: "Il ne vous en coutera que 5 petites minutes : cliquer sur le bouton 'inscription' en haut de la page",
      work2: "Recherchez votre entreprise (par nom ou n° SIRET)",
      work2_1: "La recherche se fait avec la base SIRENE des entreprises Françaises\nSon adresse est trouvée automatiquement.\nFacilitez vous la tâche nul besoin de tout retaper",
      work3: "Téléchargez vos relevés de caisse",
      work3_1: "Rentrez vos chiffres d'affaire !",
      workX: "Et voilà vous avez vos prévisons !!!",

      work4: "Explication concernant Bizzo",
      work5: "Démonstration d'utilisation",

      serv: "Pourquoi utiliser BizzO ?",
      serv1: "Ne vous est-il pas arrivé en fin de journée de vous dire :",
      serv2: "\"On a eu personne aujourd'hui !\"",
      serv3: "Ou encore :",
      serv4: "\"Je n'ai pas arrêté aujourd'hui !\"",
      servA: "Le temps",
      servA1: "L'activité de vente n'est pas la seule de votre entreprise : il faut aussi dégager du temps pour gérer le stock, les RdV avec les fournisseurs...",
      servB: "Les ressources",
      servB1: "Comment gérer les périodes de forte activités (intérim par ex) si on ne les maitrise pas à l'avance ?",
      servC: "Prévisions court/moyen terme",
      servC1: "Pour les achats de stock, la prévision de vente précise est indispensable",
      servD: "Les évènements",
      servD1: "Vous pouvez avoir un planning des évènements qui vont impacter votre activité",

      who: "A qui s'adresse BizzO ?",
      who1: "Tout commerce dont l'activité dépends de l'affluence du public sera intéressé par cette application.",
      whoA: "Evénementiels",
      whoA1: "Les commerces événementiels, tels que les salles de concert, les cinémas, les théâtres et les parcs d'attractions, dépendent de l'affluence des clients pour générer des revenus. Ces entreprises ont besoin d'un grand nombre de visiteurs pour couvrir les coûts d'exploitation et réaliser des bénéfices.",
      whoB: "Restauration",
      whoB1: "Les restaurants, cafés, bars et autres établissements de restauration dépendent également de l'affluence des clients. Les périodes de pointe, comme les heures de repas et les week-ends, sont cruciales pour la rentabilité de ces entreprises. Plus il y a de clients, plus les ventes et les bénéfices sont importants.",
      whoC: "Artisans",
      whoC1: "Les périodes de pointe, comme les soldes, les vacances et les week-ends, sont essentielles pour attirer un grand nombre de clients et augmenter les ventes.",
      whoD: "Centres commerciaux",
      whoD1: "Les périodes de pointe, comme les soldes, les vacances et les week-ends, sont essentielles pour attirer un grand nombre de clients et augmenter les ventes.",
      whoE: "Sites touristiques",
      whoE1: "Quand vons venir les touristes étranger et que vont-ils visiter",

      where: "Ou sont stockées vos données ?",
      whereA: "Vous vous posez des questions sur la sécurité de vos données ?\nVos données sont strictement confidentielles !\nVous seuls y avez accès, elle ne seront pas divulgées à qui que se soit.\nElles sont stockées et cryptées sur des serveurs privés sécurisés en France.",

      howmuch: "Combien cela vous coûte ?",
      forfait1: "Forfait gratuit",
      forfaitD1: "pendant 3 mois",
      forfaitI1: "Prévisions à 7 jours d'un indicateur",
      forfaitJ1: "Un site",
      forfaitK1: "Analyse personnalisée des évènements influants",
      forfaitL1: "Calendrier des évènements",
      forfait2: "Forfait 'petit commerce'",
      forfaitD2: "par mois",
      forfaitI2: "Prévisions à un mois d'un indicateur par site",
      forfaitJ2: "2 sites possible",
      forfaitK2: "Calendrier des évènements",
      forfaitL2: "",
      forfait3: "Forfait 'tout compris'",
      forfaitD3: "Nous contacter",
      forfaitI3: "Prévisions à 6 mois des indicateurs",
      forfaitJ3: "Multi-site",
      forfaitK3: "Analyse personnalisée des évènements importants",
      forfaitL3: "Calendrier des évènements",

      help: "Comment pouvons-nous vous aider ?",
      helpA: "Question les plus fréquentes.",
      helpQ1: "Comment je fait pour fournir mes indicateurs ?",
      helpQA1: "C'est simple : utilisez le template excel fournis. Une colonne pour les dates, une autre pour votre indicateur. Chargez le et c'est fait !",

      team: "A propos de nous",
      teamA: "BizzO est un produit innovant développé en région centre par",
      team1r: "Fondateur & CTO", /* Emmanuel */
      team1: "Experts en apprentissage automatique, en données et en développement de logiciels",
      team2r: "Réseau sociaux et commercialisation", /* Angèle */
      team2: "Ingénieur d’affaire pour le développement commercial",
      team3r: "Developpeur", /* Adile */
      team3: "Développeur web, webdesign et IA, je crée des expériences numériques innovantes, fonctionnelles et esthétiques",
      team4r: "Chargé marketing", /* Vicleche */
      team4: "Expert en marketing digital",

      contactS: "Contact",
      contactSA: "Vous avez d'autres questions ? Envoyez nous un message nous vous répondrons sous 24H",

      newinscrip: "Nouvelle inscription",
      newinscriptxt: "BizzO vous permet de prédire vos indicateurs, comme la météo !\nVous devez identifier votre entreprise à partir de son SIRET ou de son nom.",
      newinscripadd: "Ajouter une entreprise",

      usefull: "Liens utiles",
      services: "Services",
      social: "Réseaux Sociaux",
      rights: "Tous Droits Réservés",

      marketUpload: "Charger le fichier produits",

      language: 'Français',
      comment: 'Comment ?',
      pourquoi: 'Pourquoi ?',
      qui: 'Qui ?',
      combien: 'Combien ?',
      contact: 'Nous contacter',
      cgv: 'CGV',
      phone: 'Téléphone',
      mentions: 'Mentions légales',
      app: 'Application',
      graph: 'Graphiques',
      timeline: 'Timeline',
      marketing: 'Marketing',
      blog: 'Blog',
      bloglist: 'Articles publiés',
      bloglistall: 'Tous articles',
      facebook: 'Facebook',
      admin: 'Administration',
      login: 'Connexion',
      logout: 'Déconnexion',
      register2: "S'enregistrer",

      planningWeek: 'Prévisions de la semaine',
      planning: 'Prévisions',
      lastdate: 'dernière date',

      timelineAddEvent: "Ajout d'un évènement",
      appUploadFile: "Télécharger des fichiers",
      // Add any other translations you need
    },
    'en': {
      indicator: "indicator",
      company: "Company",
      bizzoserv: "Bizzo to serve you",
      welcome: "Welcome",
      evtgrp: "event groups",
      save: "Save",
      cancel: "Cancel",
      saveddata: "Saved data",
      events: "Events",
      meteo: "Forecast",
      rain: "Rain",

      devise:'Bizzo, Your business forecast !',
      devise2: "Anticipate the future of your business",
      devise3: "With Bizzo, put AI at the service of your business.",
      devise4: "Activity, indicators, turnover : get accurate forecasts :",
      startUse: "Start to use it",
      learnMore: "Further explanations",
      bonus: "Bonus",
      register: "Register",

      about: "How does BizzO work ?",
      about1: "Your business depend on numerous parameters",
      about1_1: "Weather",
      about1_2: "Day in week, in month",
      about1_3: "Bank holydays, en France and abroad",
      about1_4: "Period with soldes",
      about1_5: "Local events",
      about1_6: "other",
      about2: "All these parameters can be forecasted !",
      about2_1: 'It is then possible to planify your indicators',
      about2_2: 'The more you provide historical data, the more predictions are accurate (using Machine Learning)',
      about3: "BizzO also uses informations from free Wifi network provided by Val-de-Loir Numerique to forcast visitors frequentation",

      work: "How do you obtain your forecast in order to better planify your activity <strong>Simple as easy !</strong>",
      work1: "It will cost you only 5 minutes : click on the 'Register' button on top of page",
      work2: "Look up you company (by name or SIRET n°)",
      work2_1: "Search is done on SIRENE register.\nAddress is automatically retreived.\nMake it easy for you : no need to fill address details",
      work3: "Upload your data files",
      work3_1: "Provide your indicator historic",
      workX: "Et voilà, you get your forecast !!!",
      work4: "Explanations concerning Bizzo",
      work5: "Use demonstration",

      serv: "Why would you use BizzO ?",
      serv1: "Did you ever finish you daywork saying :",
      serv2: "\"There were nobody today !\"",
      serv3: "Or else :",
      serv4: "\"I couldn't sit today !\"",
      servA: "Time",
      servA1: "Selling is not the only activity in your shop : you also spend time for stock inventory, meeting providers, ...",
      servB: "Ressources",
      servB1: "How to manage pick of activity if you cannot plan activity ?",
      servC: "short / medium term forecast",
      servC1: "In order to plan quantity to order",
      servD: "Events",
      servD1: "You get planning of important events impacting your activity",

      who: "Is it for you ?",
      who1: "Any business whose activity depends on the influx of the public will be interested in this application",
      whoA: "Events",
      whoA1: "Event-driven businesses, such as concert halls, cinemas, theaters and amusement parks, depend on customer attendance to generate revenue. These businesses need a large number of visitors to cover operating costs and make a profit",
      whoB: "Restoration",
      whoB1: "Restaurants, cafes, bars and other catering establishments also depend on the influx of customers. Peak periods, such as meal times and weekends, are crucial to the profitability of these businesses. The more customers, the greater the sales and profits",
      whoC: "Craftsmen",
      whoC1: "Peak periods, such as sales, holidays and weekends, are key to attracting large numbers of customers and increasing sales",
      whoD: "Shopping centers",
      whoD1: "Peak periods, such as sales, holidays and weekends, are key to attracting large numbers of customers and increasing sales",
      whoE: "Tourist attractions",
      whoE1: "When will foreign tourists come and what will they visit ?",

      where: "Where are stored your data ?",
      whereA: "Do you have questions about the security of your data?\nYour data is strictly confidential !\nOnly you have access to it, it will not be disclosed to anyone.\nThey are stored and encrypted on secure private servers in France.",

      howmuch: "How much does it cost ?",
      forfait1: "Free plan",
      forfaitD1: "For 3 month",
      forfaitI1: "7 day forecast of one indicator",
      forfaitJ1: "One site",
      forfaitK1: "Personalized analysis of influential events",
      forfaitL1: "Event Calendar",
      forfait2: "Plan 'small business'",
      forfaitD2: "per month",
      forfaitI2: "one month forecast of one indicator per site",
      forfaitJ2: "2 sites",
      forfaitK2: "Event Calendar",
      forfaitL2: "",
      forfait3: "Pan 'all inclusive'",
      forfaitD3: "Contact us",
      forfaitI3: "six month forecast of indicators",
      forfaitJ3: "Multi-site",
      forfaitK3: "Personalized analysis of important events",
      forfaitL3: "Event Calendar",

      help: "How can we help you ?",
      helpA: "Frequent Question",
      helpQ1: "How can I provide indicators ?",
      helpQA1: "Simple : use the provided excel template. One column for dates, another for your indicator. Load it and it's done !",

      team: "About us",
      teamA: "BizzO is an innovative product developed in the central region by",
      team1r: "Funder & CTO", /* Emmanuel */
      team1: "Experts in machine learning, data and software development",
      team2r: "Social network & marketing", /* Angèle */
      team2: "Business engineer for business development",
      team3r: "Developper", /* Adile */
      team3: "Web developer, webdesign and AI, I create innovative, functional and aesthetic digital experiences",
      team4r: "Marketing", /* Vicleche */
      team4: "Digital marketing expert",

      contactS: "Contact",
      contactSA: "You have further questions ? Send us a message we will answer you within 24 hours",

      newinscrip: "New inscription",
      newinscriptxt: "BizzO allows you to predict your indicators, just like weather\nYou need to identify your company from its SIRET or its name",
      newinscripadd: "Add your company",

      usefull: "Usefull links",
      services: "Services",
      social: "Social networks",
      rights: "All rights reserved",

      marketUpload: "Upload product file",

      language: 'English',
      comment: 'How?',
      pourquoi: 'Why?',
      qui: 'Who?',
      combien: 'How much?',
      contact: 'Contact us',
      cgv: 'T&C',
      phone: 'Phone',
      mentions: 'Legal notice',
      app: 'Application',
      graph: 'Graphs',
      timeline: 'Timeline',
      marketing: 'Marketing',
      blog: 'Blog',
      bloglist: 'Published Articles',
      facebook: 'Facebook',
      admin: 'Admin',
      login: 'Login',
      logout: 'Logout',
      register2: 'Register',

      planningWeek: 'Weekly planning',
      planning: 'Planning',
      lastdate: 'last date',

      timelineAddEvent: "Add event",
      appUploadFile: "Upload files",

    },

};

const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;
