<style scoped>
.custom-file-label {
  white-space: wrap;
  color: blue;
}
label {
  color: blue;
}

h1{
  margin-top: 130px;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 480px;
  font-family: sans-serif;
  //margin: 100px auto;
  padding: 20px;
}
.drop-area.highlight {
  border-color: purple;
}

.pre {white-space: pre;}
</style>

<template>
  <div>

    <div v-if="!this.company">
        <NoCompany @alert="upalert" @reload='reload'/>
    </div>

    <div v-if="this.company">
    <h1>{{ $t('company') }} : {{this.company.nom_complet}}</h1>

    {{ $t('indicator') }} : <b-form-select id="site2" :options="options" v-model="indicatorID" @change='indicChange' size="sm" class="mt-3">
          </b-form-select><br/>

    <button type="button" class="btn btn-success btn-sm" @click="clickXls">{{ $t('appUploadFile') }}</button><br/>

    <indicator :data=indicator @update="onCreated" @addMessage="addMessage"/>

    <button v-if="false & this.company.nom_complet" type="button" class="btn btn-success btn-sm" v-b-modal.addSiteModal>Ajouter Site</button>
    </div>


    <b-modal ref="uploadXlsModal" id="uploadXlsModal" size="lg" title="Envoi de Fichier" hide-footer>
      <b-form @submit="onSubmitXls" @reset="onReset" class="w-100">

        <b-form-group id="form-type-group" label="Type de fichier :" label-for="form-type-choix">
          <b-form-select id="form-type-choix" v-model="importType" size="sm" class="mt-3">
            <option value='oriane'>relevés de caisse type Oriane</option>
            <option value='simple'>relevés Excel simple</option>
            <option value='FEC'>Fichier FEC</option>
          </b-form-select>
        </b-form-group>

        <div v-if="importType=='oriane'">
        format basé sur ce <a href="template_caisse.xls">template Oriane</a>:<br/><br/>
        <b-form-file
              accept="application/vnd.ms-excel"
              v-model="fileOriane"
              name="fileOriane"
              multiple
              :state="Boolean(fileOriane)"
              placeholder="Choisissez les fichiers ou déposez les ici..."
              drop-placeholder="Déposez les là..."
            ></b-form-file>
            <div style="display:none" class="mt-3">Fichiers: {{ fileOriane ? fileOriane.name : '' }}</div>
        </div>

        <div v-if="importType=='FEC'">
            <div class="drop-area">
                FEC : <input type="file" ref="UploadFile" class="fileElem" accept="plain/*" @change="readFEC">
            </div>
            Journal : <b-form-select :options="fec_journaux" v-model="fec_journal" @change='selChange' size="sm" class="mt-3"></b-form-select><br/>
            Compte : <b-form-select :options="fec_cpts" v-model="fec_cpt" @change='selChange' size="sm" class="mt-3"></b-form-select><br/>
            <textarea rows="10" cols="50" readonly v-model="fec_txt"></textarea>
        </div>

        <div v-if="importType=='simple'">
        format basé sur ce <a href="template_indicateur.xls">template indicateur</a>:<br/><br/>
        <b-form-file
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              v-model="fileExcel"
              name="fileExcel"
              multiple
              :state="Boolean(fileExcel)"
              placeholder=""
              drop-placeholder="Déposez les là..."
            ></b-form-file>
        </div>

        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>


    <b-modal ref="addIndicatorModal" id="addIndicatorModal" title="Ajout indicateur" hide-footer>
      <b-form @submit="onSubmitaddIndicator" @reset="onReset" class="w-100">
      <b-form-group id="form-indicator-group" label="Indicateur:" label-for="form-indicator-input">
          <b-form-input id="form-indicator-input" type="text" v-model="addIndicatorForm.name" required placeholder=""/>
        </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>

    <b-modal ref="addSiteModal" id="addSiteModal" title="Add Site" hide-footer>
      <b-form @submit="onSubmitaddSite" @reset="onReset" class="w-100">
      <b-form-group id="form-site-group" label="Site:" label-for="form-site-input">
          <b-form-input id="form-site-input" type="text" v-model="addSiteForm.name" required placeholder=""/>
        </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>



  </div>
</template>

<script>
import IndicatorList from './IndicatorList.vue';
import NoCompany from '@/components/NoCompany.vue'
import AppliDS from "../services/AppliDS";

export default {
  props: ['login'],
  data() {
      return {
        company: null,

          fec_journaux: [], // {value:'a', text:'A'}
          fec_journal: null,
          fec_cpts: [],
          fec_cpt: null,
          fec_lines: [],
          fec_txt: "...",

        options: [], // Select Site/indicator
        indicatorID: null,

        res_task: null,

        indicator: null,
        addSiteForm: {},
        addIndicatorForm: {},
        messages: [],
        fileOriane: null,
        fileExcel: null,
        importType: 'simple',
      };
    },
  computed: {
  },
  components: {
      indicator: IndicatorList,
      NoCompany,
    },
  methods: {
    upalert(variant, message) {
        this.$emit('alert', variant, message);
    },
    sendFEC() {
        let cpt=this.fec_cpts.find(x => x.value==this.fec_cpt);
        this.$emit('alert', 'primary', 'FEC envoyé');
        AppliDS.sendFEC({journal: this.fec_journal, cpt: cpt, data: this.fec_lines})
          .then((res) => {
              console.log(res);
              this.$emit('alert', 'primary', 'FEC : '+res.data);
              this.onCreated();
          })
    },
    selChange() {
        let doc = this.$refs.UploadFile.files[0];
        this.$papa.parse(doc, {
            header: true,
            complete: (data) => {
            console.log(data, this.fec_journal, this.fec_cpt);
            let lines=data.data.filter(x => x.JournalCode==this.fec_journal && x.CompteNum.trim()==this.fec_cpt);
            console.log('lines', lines);
            this.fec_lines=[];
            this.fec_txt="";
                lines.map((x) => {
                    let montant=parseFloat(x.Credit)-parseFloat(x.Debit);
                    let dt=x.EcritureDate.substring(0,4)+'-'+x.EcritureDate.substring(4,6)+'-'+x.EcritureDate.substring(6,8)
                    this.fec_lines.push(dt+' '+montant);
                    this.fec_txt+=dt+' '+montant+"€\n";
                });
                //this.fec_lines = lines.map(x => x.EcritureDate+" "+(parseFloat(x.Debit)-parseFloat(x.Credit)));
                //this.fec_txt = lines.map(x => x.EcritureDate+" "+(parseFloat(x.Debit)-parseFloat(x.Credit))+'€\n');
            }
        })
    },
    readFEC() {
        let doc = this.$refs.UploadFile.files[0];
        this.$papa.parse(doc, {
            header: true,
            complete: (data) => {
                let journaux = [];
                this.fec_journaux = [];
                let cpts = [];
                this.fec_cpts = [];
                data.data.map(x => {
                    if (x.JournalCode && !journaux[x.JournalCode]) {
                        this.fec_journaux.push({text: x.JournalLib, value: x.JournalCode});
                        journaux[x.JournalCode]=true;
                        }
                    if (x.CompteNum && !cpts[x.CompteNum]) {
                        this.fec_cpts.push({text: x.CompteNum+'-'+x.CompteLib, value: x.CompteNum});
                        cpts[x.CompteNum]=true;
                        }
                    });
                this.fec_cpts=this.fec_cpts.sort((x,y) => x.text>y.text);
                this.fec_journal=this.fec_journaux[0].value;
                this.fec_cpt=this.fec_cpts[0].value;
                this.selChange();
            }})
    },
    indicChange(indic_no) {
        let indic = null;
        this.company.sites.map(site => (indic = site.indicators.find(indic => indic.id==indic_no) || indic));
        //console.log('trouve', indic);
        this.indicator = indic;
    },
    clickXls() {
        this.$refs.uploadXlsModal.show();
    },
    onSubmitXls(evt) {
        evt.preventDefault();
        this.$refs.uploadXlsModal.hide();

        if (this.importType=='FEC') {
            return this.sendFEC();
        }

        const formData = new FormData()
        if (this.fileOriane) this.fileOriane.forEach(file => {
            formData.append('fileOriane', file);
        })
        if (this.fileExcel) this.fileExcel.forEach(file => {
            formData.append('fileExcel', file);
        })
        console.log(this.updIndicatorForm);
        AppliDS.sendFile(formData)
                  .then((res) => {
                    this.res_task=res.data;
                    console.log(res.data);
                    this.$emit('alert', 'info', 'Données chargées...');
                    this.onPull(); // Attente avant de recharger
                  })
    },
    onPull() {
        AppliDS.getTask(this.res_task.tasks.at(-1))
        .then(res=>{
            console.log('PULL', res.data)
            if (res.data=='SUCCESS') {
                this.$emit('alert', 'success', 'Prévisions mises à jour !');
                this.onCreated();
                }
            else {setTimeout(this.onPull, 500)}
            })
    },
    onSubmitaddSite(evt) {
        evt.preventDefault();
        this.$refs.addSiteModal.hide();
        console.log(this.addSiteForm);
        this.onCreated();
    },
    addIndicator(site) {
        this.addIndicatorForm=site;
    },
    onSubmitaddIndicator(evt) {
        evt.preventDefault();
        this.$refs.addIndicatorModal.hide();
        console.log(this.addIndicatorForm);
        AppliDS.updateIndicator(this.addIndicatorForm)
                        .then((res) => {
                            console.log(res.data);
                            this.onCreated();
                            this.addMessage(res.data);
                        })
    },
    onReset(evt) {
        evt.preventDefault();
        this.addIndicatorForm={};
        this.addSiteForm={};
    },
    addMessage(msg) {
        this.messages.push(msg);
        setTimeout(()=>this.messages.shift(), 1000);
    },
    onCreated() {
      AppliDS.getCompany()
              .then((res) => {
                console.log(res.data);
                this.company = res.data;
                this.options = this.company.sites.map(site => ({
                        label: site.name || site.adresse_complete,
                        options: site.indicators.map(indic => ({
                            value: indic.id,
                            text: indic.name+" "+indic.id})),
                        }));
                this.indicator = this.company.sites[0].indicators[0];
                console.log(this.options);
                this.$emit('alert', 'primary', 'Sites: '+this.company.sites.length); // TODO ?
              })
    },
  },
  created() {
    this.onCreated();
      }
};
</script>
