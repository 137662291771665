<style scoped>

/*--------------------------------------------------------------
# Sections General - same as Home.vue
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--couleur-principale);;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--couleur-secondaire);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.how-it-works2 .content {
  margin-top: 50px;
  padding: 60px 100px 0 100px;
}

.end-content h3{
  padding: 0 0 50px 100px;
  color: var(--couleur-principale);
}

.how-it-works2 .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: var(--couleur-principale);
}

.how-it-works2 .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.how-it-works2 .content p {
  font-size: 15px;
  color: #848484;
}

.how-it-works2 .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.how-it-works2 .accordion-list {
  padding: 0 100px 60px 100px;
}

.how-it-works2 .accordion-list ul {
  padding: 0;
  list-style: none;
}

.how-it-works2 .accordion-list li+li {
  margin-top: 15px;
}

.how-it-works2 .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.how-it-works2 .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.how-it-works2 .accordion-list span {
  color:var(--couleur-secondaire);
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.how-it-works2 .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.how-it-works2 .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.how-it-works2 .accordion-list .icon-show {
  display: none;
}

.how-it-works2 .accordion-list a.collapsed {
  color: #343a40;
}

.how-it-works2 .accordion-list a.collapsed:hover {
  color: var(--couleur-secondaire);
}

.how-it-works2 .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.how-it-works2 .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {

  .how-it-works2 .content,
  .how-it-works2 .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .how-it-works2 .img {
    min-height: 400px;
  }

  .how-it-works2 .content {
    padding-top: 30px;
  }

  .how-it-works2 .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .how-it-works2 .img {
    min-height: 200px;
  }
}

</style>

<template>

  <section id="howwork" class="how-it-works">

    <div class="container-fluid how-it-works2 section-bg" data-aos="fade-up">

      <div class="row">

        <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

          <div class="content">
            <h3 v-html="$t('work')"></h3>

          </div>

          <div class="accordion-list">
            <ul>
              <li>
                <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1"><span>01</span>{{ $t('register') }} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                  <p>{{ $t('work1') }}</p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span> {{ $t('work2') }} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                  <p class="nl">{{ $t('work2_1') }}</p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"><span>03</span> {{ $t('work3') }} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                  <p>{{ $t('work3_1') }}</p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed"><span>Vidéo</span> {{ $t('work4') }} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                  <div class="video-youtube"
                       data-video-id="2Pb1H5MAURk"
                       data-video-start="0"
                       style="width: 840px; height: 630px;"
                       >
                       <a href="#" class="mt-5">Veuillez accepter les cookies pour lire cette vidéo.</a>
                  </div>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" class="collapsed"><span>Vidéo</span> {{ $t('work5') }} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                  <div class="video-youtube"
                       data-video-id="8ut2M6qdKbM"
                       data-video-start="0"
                       style="width: 840px; height: 630px;"
                       >
                       <a href="#" class="mt-5">Veuillez accepter les cookies pour lire cette vidéo.</a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

        </div>

        <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" style='background-image: url("why-us.png");' data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
      </div>

      <div class="end-content">
          <h3>{{ $t('workX') }}</h3>
      </div>
    </div>
  </section>

</template>

<script>
import $ from 'jquery'
export default {
    name: "HowWork",
    components: {
    },
  mounted() {

    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function(axeptio) {
      axeptio.on("cookies:complete", function(choices) {
        console.log('AXEPTIO HowWork.vue', choices);
        if (choices.youtube) {
                    $(".video-youtube").each(function() {
                        $(this)
                            .after($("<iframe>")
                                .attr("src", "https://www.youtube.com/embed/" + $(this).data("video-id") + "?rel=0&amp;start=" + $(this).data("video-start"))
                                .attr("style", "width: 840px; height: 630px;")
                                .attr("allow", "fullscreen")
                            )
                            .remove();
                    });
                }
      })
    })
  },
}
</script>
