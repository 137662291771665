import axios from 'axios';

class AppliDS {
  //---------------------------------------------------------------------
  getCompany() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/company');
  }

  updateCompany(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/company', data);
  }
  listCompany(query) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/list_company?q='+query)
  }

  //---------------------------------------------------------------------
  sendFEC(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/fec', data)
  }
  sendFile(data) {
    return axios.put(process.env.VUE_APP_ROOT_API+'/indicator', data, { 'Content-Type': 'multipart/form-data' })
  }

  updateIndicator(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/indicator', data)
  }
  deleteIndicator(id) {
    return axios.delete(process.env.VUE_APP_ROOT_API+'/indicator/'+id)
  }
  saveIndicator(id, data) {
    return axios.put(process.env.VUE_APP_ROOT_API+'/indicator/'+id, data)
  }
  getIndicator(id) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/indicator/'+id)
  }
  getIndicatorBySite(site) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/indicators?site='+site)
  }

  //---------------------------------------------------------------------
  getEventsBySite(site) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/events?site='+site)
  }
  saveEvent(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/events', data)
  }
  deleteEvent(id) {
    return axios.delete(process.env.VUE_APP_ROOT_API+'/event/'+id);
  }
  //---------------------------------------------------------------------

  getPlanning() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/planning')
  }

  //---------------------------------------------------------------------
  getTask(id) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/task/'+id)
  }

  //---------------------------------------------------------------------

  getCrm() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/crm/contacts')
  }
  updateCrm(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/crm/contacts', data)
  }
}

export default new AppliDS();
