/*
Ici mes commentaires
  this.$route.params.id : si click sur un article.
  Si user.role&1, bouton addBlog

  Si admin : ajout bouton 'ajout', 'edit' et liste tous les blog
  prop.edit : true/false
  $route.params.id : indique si visualisation un seul article

*/


<template>
  <section id="services" class="why-us section-bg">
    <div class="container" data-aos="fade-up">

        <b-modal ref="blogModal" id="blogModal" size="xl" title="Blog" hide-footer>
          <div v-if="blog">
            <div>
            Title: <input type="text" name="name" id="name" v-model="blog.title" size="30"/> (created {{blog.created}})
            <button v-if="blog.title" type="button" class="btn btn-outline-primary" @click="blogSave">{{$t('save')}}</button>
            <button type="button" class="btn btn-outline-secondary" @click="blogCancel">{{$t('cancel')}}</button>
            <button type="button" class="btn btn-outline-warning" @click="blogPublish">{{$t('publish')}}</button>
            <button type="button" class="btn btn-outline-danger" @click="blogDelete">{{$t('delete')}}</button>

            <ImageUpdater :image="blog.image" @imageChange="imageChange"/>

            <vue-editor v-model="blog.body"/>
            </div>
          </div>
        </b-modal>


      <div class="section-title">
        <h2>{{ $t('blog') }}</h2>
        <p>{{ $t(edit ? 'bloglistall' : 'bloglist') }}</p>
        <button v-if="edit" type="button" class="btn btn-outline-primary" @click="clickBlog">{{$t('newBlog')}}</button>
      </div>

      <div class="row">

        <div v-for="(blog, i) in blogs" :key="i" :class="($route.params.id) ? '' : 'col-sm'">
        <div v-if="!$route.params.id || $route.params.id==blog.id" class="card"> <!-- style="width: 18rem;" -->
          <img :src="blog.image" class="card-img-top" :alt="blog.title">
          <div :class="'card-body '+($route.params.id ? '':'limited')">
            <h5 class="card-title"><a :href="'#'+blog.id" @click="$router.push(name+blog.id)">{{blog.title}}</a></h5>
            <p>du {{blog.created}}</p>
            <p class="card-text" v-html="blog.body"></p>
          </div>
          <button v-if="edit" @click="clickBlog(i)" class="btn btn-outline-primary">edit</button>
          <button v-if="$route.params.id" @click="$router.go(-2)" class="btn btn-outline-primary">retour</button>
        </div>
        </div>

      </div>

    </div>
  </section>
  <!-- End Services Section -->

</template>

<script>
import BlogDS from "../services/BlogDS";
import ImageUpdater from '@/components/ImageUpdater.vue'
import { VueEditor } from "vue2-editor";

export default {
  name: 'BlogList',
  props:['edit',
         ],
  //setup(props) {console.log('SETUP', props)},
  //watch: {test: function(){console.log('WATCH test')}},
  data() {
    return {
        name: '/blog-business-intelligence-appli-Val-Loire-pme/',
        blogs: null,
        blog: null, //{uuid:null, title:'', body:''}
    }
  },
  components: {
    ImageUpdater, VueEditor,
  },
  methods: {
    updateList() {
        console.log('this.edit=', this.edit)
        BlogDS.getAll(this.edit)
        .then((res) => {
            this.blogs = res.data;
        });
    },
    clickBlog(i) {
      console.log('clickBlog', i, typeof(i));
      if (typeof(i)=='number') {
        this.blog=this.blogs[i];
        console.log('  blog', this.blog);
      } else {
        this.blog={uuid:null, title:'', body:''};
      }
      this.$refs.blogModal.show();
    },
    blogCancel() {
      this.$refs.blogModal.hide();
    },
    blogSave() {
        BlogDS.update(this.blog.uuid, this.blog)
        .then((res)=>{
            console.log(res)
            this.blog=null;
            this.$refs.blogModal.hide();
            this.updateList();
            })
    },
    blogDelete() {
      if (confirm('Suppression ?')) {
        BlogDS.delete(this.blog.uuid)
        .then(()=>{
            this.blog=null;
            this.$refs.blogModal.hide();
            this.updateList();
        });
      }
    },
    blogPublish() {
        this.blog.published=true;
    },
    imageChange(img) {
        this.blog.image=img;
    },
  },
  mounted() {
    this.updateList();
  },
}
</script>

<style>
div.card {margin: 10px;}

</style>

<style scoped>

div.blog__title {color: red; display: block; font-weight: bold; text-align: center;}
div.blog__created {color: blue; display: inline-block; font-size: 80%;}
div.blog__body {color: #111; font-size: 100%;}

ul.blog__feed{
    display: flex;
    align-items: flex-start;
    }
li.blog__preview{
    width: calc(100% / 3 - 2.5rem);
    margin: 1.25rem 1.25rem 2.5rem;
    background-color: #8EC5FC;
    background-image: linear-gradient(45deg, #8EC5FC 0%, #00bf63 100%);
    }
div.blog__edit{
    margin-top: 57px;
    }



img {
    max-width: 200px;
}
.limited {
  height: 300px;
  overflow: clip;
}


.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--couleur-principale);;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--couleur-secondaire);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
  margin-bottom: 10px;
}

.why-us .icon-box .icon {
  margin-bottom: 10px;
}

.why-us .icon-box .icon i {
  color:var(--couleur-secondaire);
  font-size: 36px;
  transition: 0.3s;
}

.why-us .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;

  color:var(--couleur-principale);
  transition: ease-in-out 0.3s;
}

.why-us .icon-box h4 div{
  display: block;
  font-size: 50%;
  color: gray;
}

.why-us .icon-box h4 a {
}

.why-us .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: blue;
}

p.blog-body p img {
  border: dashed red 1px;
  max-width: 200px;
}

.why-us .icon-box:hover {
  transform: translateY(-10px);
}

.why-us .icon-box:hover h4 a {
  color: var(--couleur-secondaire);
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
    /* margin-top: 30px; */
    margin-bottom: none;
}
</style>