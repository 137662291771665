import Vue from 'vue'
import { BootstrapVue, IconsPlugin, AlertPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import AOS from 'aos';
import GLightbox from 'glightbox/dist/js/glightbox.min';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'boxicons/css/boxicons.min.css';
import 'glightbox/dist/css/glightbox.min.css';
import 'aos/dist/aos.css';
import i18n from './plugins/i18n';
import VueGoodTable from 'vue-good-table';
import VuePapaParse from 'vue-papa-parse';

import { initCustomScripts } from './javascript/custom.js';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-good-table/dist/vue-good-table.css'

//import i18n from '@/plugins/i18n';

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(AlertPlugin)
Vue.use(VueGoodTable);
//Vue.use(i18n)
Vue.use(VuePapaParse);

Vue.config.productionTip = false

new Vue({
  //i18n,
  router,
  i18n,
  render: h => h(App),
  mounted() {
    // i18n,
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
    GLightbox();
    
    initCustomScripts();
    // Initialisation de Waypoint
    const waypoints = document.querySelectorAll('.your-target-element');
    waypoints.forEach(element => {
      new Waypoint({
        element: element,
        handler: function() {
          // Déclenchez votre code ici
        },
        offset: '75%', // Personnalisez l'offset si nécessaire
      });
    });


  }
}).$mount('#app')
