<style scoped>
img#logo {
            width: 100px;
        }
li img {width: 150px;}
h3.bonnus {color: #14751f;}
</style>

<template>
  <div id="app">

    <company-page :login=login ref="company" v-if="login && login.email" @alert="(variant, message)=>this.$emit('alert', variant, message)" />


  </div>
</template>

<script>
import CompanyPage from '@/components/CompanyPage.vue'
import LoginDS from "../services/LoginDS";

export default {
  name: 'GraphView',
  data() {
      return {
        loginForm: {},
        registerForm: {},
        showMessage: false,
        login: {},
      };
    },
  components: {
    CompanyPage
  },
  methods: {
      initForm() {
            this.loginForm = {};
            this.registerForm = {};
          },
      onSubmitLogout(evt) {
            evt.preventDefault();
            LoginDS.logout()
                .then((res) => {
                    console.log(res.data);
                    this.login = res.data;
                    this.$emit('update', {});
                })
          },
      onSubmitLogin(evt) {
        //alert('onSubmitRegister');
        //console.log('onSubmitLogin')
            evt.preventDefault();
            this.$refs.loginModal.hide();
            console.log(this.loginForm);
            LoginDS.create(this.loginForm)
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                    this.login = res.data;
                    this.$emit('update');
                })
            this.initForm();
           },
      onSubmitRegister(evt) {
        //alert('onSubmitRegister');
            evt.preventDefault();
            this.$refs.registerModal.hide();
            //const payload = {user:this.registerForm.user, email:this.registerForm.email, password:this.registerForm.password, password2:this.registerForm.password2};
            LoginDS.register(this.registerForm)
                .then((res) => {
                    console.log(res.data);
                    this.login = res.data;
                    console.log('register', res.data);
                    alert('Vous pouvez vous connecter (bouton login)')
                    //this.$emit('update');
                })
            this.initForm();
      },
      onReset(evt) {
        console.log('onReset')
            evt.preventDefault();
            this.$refs.loginModal.hide();
            this.$refs.registerModal.hide();
            this.initForm();
      },
  },
  created() {
    LoginDS.get()
              .then((res) => {
                this.login = res.data;
                this.$emit('alert', 'primary', this.$i18n.t('welcome')+' '+res.data.email);
              })
  },
};
</script>
