<template>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">

        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h1>{{ $t('devise') }}</h1>
                    <h2>{{ $t('devise2') }},</h2>
                    <h2>{{ $t('devise3') }}</h2>
                    <h2>{{ $t('devise4') }}</h2>
                    <div class="d-flex justify-content-center justify-content-lg-start div-hero-btn" >
                        <a href="#contact" class="btn-get-started scrollto">{{ $t('startUse') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 order-1 order-lg-2 hero-img image-container computer-container" data-aos="zoom-in" data-aos-delay="200">
                  <my-swiper/>
                    <img src="../../public/computer_frame.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

    </section>
    <!-- End Hero -->
</template>

<script>
import MySwiper from "./MySwiper.vue";
export default {
    name: "HeroSection",
    components: {
    MySwiper,
 

  },
}
</script>

<style>
:root {
  --couleur-principale: #2aa75a;
  --couleur-secondaire:#7d4ffe ;
  --couleur-tertiaire: #96e8b5;
  --couleur-texte: #444444;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.computer-container {
  position: relative;
}

.computer-container img {
  width: 80%;
}

.computer-container .mySwiper {
  position: absolute;
  top: 5%; /* Ajustez cette valeur pour déplacer le swiper verticalement */
  left: 15%; /* Ajustez cette valeur pour déplacer le swiper horizontalement */
  width: 70%; /* Ajustez cette valeur pour modifier la largeur du swiper */
  height: 62%; /* Ajustez cette valeur pour modifier la hauteur du swiper */
}
.image-container {
  display: flex;
  justify-content: center; /* Centrage horizontal */
  align-items: center; /* Centrage vertical */
  height: 100%; /* Hauteur de 100% pour prendre toute la hauteur disponible */
}

#hero {
  width: 100%;
  height: 80vh;
  background: var(--couleur-principale);
}

#hero .container {
  padding-top: 72px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: var(--couleur-secondaire);
}

#hero .btn-get-started:hover {
  background: var(--couleur-secondaire);
}

#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: var(--couleur-secondaire);
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {




  #hero {
    height: 100vh;
    text-align: center;
  }

  #hero .animated {
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 200px;
  }

  #hero .hero-img img {
    width: 50%;
  }

  #hero .div-hero-btn{

    margin-bottom: 200px;
  }
}

@media (max-width: 768px) {

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

</style>