<style scoped>
h1{
  margin-top: 130px;
}
h3 {
    color: black;
    }
td, th {
    font-weight: normal;
    font-size: 2cqw;
     }
.nw {white-space: nowrap;}

svg {
  max-width: 100vh;
  display : none; /* TODO cache SVG */
}

</style>

<template>
  <div v-if="!this.company">
        <NoCompany @alert="upalert" @reload='reload'/>
  </div>
  <div v-else>
      <h1>{{ $t('planningWeek') }}</h1>

<svg
   width="167.9882mm"
   height="86.186737mm"
   viewBox="0 0 167.9882 86.186737"
   version="1.1"
   id="jauge"
   xml:space="preserve"
   inkscape:version="1.2.2 (732a01da63, 2022-12-09, custom)"
   sodipodi:docname="jauge.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview7"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="false"
     inkscape:zoom="1.1893044"
     inkscape:cx="314.04912"
     inkscape:cy="192.96994"
     inkscape:window-width="1920"
     inkscape:window-height="1016"
     inkscape:window-x="0"
     inkscape:window-y="27"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer1" /><defs
     id="defs2" /><g
     inkscape:label="Calque 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-21.787301,-34.590137)"><g
       id="g2742"><path
         style="fill:#ff0000;stroke-width:0.325591"
         d="M 45.129028,60.391112 C 30.199996,75.975062 21.841655,96.707057 21.787301,118.28787 H 41.289986 C 41.3446,102.07135 47.505973,86.4705 58.546814,74.592863 Z"
         id="path1284" /><path
         style="fill:#4fc45b;fill-opacity:1;stroke-width:0.325591"
         d="m 166.43739,60.387012 -13.43484,14.219804 c 11.0421,11.87221 17.20774,27.467684 17.26923,43.681054 H 189.7755 C 189.7232,96.706128 181.36609,75.97253 166.43739,60.387012 Z"
         id="path1282" /><path
         style="fill:#f5ff00;fill-opacity:1;stroke-width:0.325591"
         d="m 110.00259,34.590137 v 19.516638 c 13.53196,0.894151 26.43778,6.030948 36.88302,14.680241 L 160.2698,54.620439 C 146.18216,42.581815 128.50904,35.53949 110.00259,34.590137 Z"
         id="path1280" /><path
         style="fill:#ffaf00;fill-opacity:1;stroke-width:0.325591"
         d="M 101.55969,34.590137 C 83.043355,35.516726 65.353741,42.543042 51.248035,54.57393 L 64.670988,68.780815 C 75.118492,60.132466 88.026406,54.997617 101.55969,54.106258 Z"
         id="circle358" /></g>
      <g >
      <path
       id="indicateur"
       :style="'fill:'+this.color+';stroke-width:0.262016'"
       d="m 75.416338,120.53608 -51.801954,-9.45994 a 9.8377256,9.8377256 0 0 0 -0.377723,-0.0688 l -0.321553,-0.0587 4.13e-4,0.004 a 9.8377256,9.8377256 0 0 0 -8.705107,3.16746 9.8377256,9.8377256 0 0 0 0.752553,13.89214 9.8377256,9.8377256 0 0 0 9.186948,2.15398 z m -50.477592,-3.15001 a 4.4501262,4.4501262 45 0 1 0.340063,6.28422 4.4501262,4.4501262 45 0 1 -6.283834,0.34041 4.4501262,4.4501262 45 0 1 -0.340801,-6.28419 4.4501262,4.4501262 45 0 1 6.284572,-0.34044 z"
       inkscape:transform-center-x="-21.608913"
       inkscape:transform-center-y="0.040579172"
       inkscape:label="indicateur"
       :transform="'translate(80 0 ) rotate('+(-this.rot)+' 22 120)'"

       /></g>
       </g></svg>


      <div v-for="(site, index) in data" :key="index">
        <h2>{{site.SIRET}} ({{site.adresse_complete}})</h2>

        <div class="table-responsive">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
                <th>Date</th>
                <th>{{ $t('meteo') }}</th>
                <th v-for="(x, index) in site.indicators" :key="index"><b>{{x.name}}</b><br/>{{ $t('lastdate') }} : <div class="nw">{{x.lastdate}}</div></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(x, index1) in site.days" :key="index1" :class="index1==0 ? 'table-primary' : ''">
                <th><b class="nw">{{x.date}}</b><br/>Saint(e) {{x.saint}}</th>
                <td>{{x.tmin}}/{{x.tmax}}°C<br/><div v-if='x.rr10'>{{ $t('rain') }} : {{x.rr10}}mm</div></td>
                <td v-for="(x, index2) in site.indicators" :key="index2">{{x.values[index1].pred}}</td>
            </tr>
          </tbody>
        </table>
        </div>

        <h3>{{ $t('events') }}</h3>
        <div class="event" v-for="(evt, index) in site.events" :key="index">
        {{evt.start_date}} → {{evt.end_date}} : {{evt.name}}
        </div>
      </div>
  </div>
</template>

<script>
import NoCompany from '@/components/NoCompany.vue'
import AppliDS from "../services/AppliDS";
//var $ = require('jquery');

export default {
  name: 'PlanningView',
  data() {
      return {
        data: [],
        company: null,
        rot: 0,
        color: 0,
      };
    },
  components: {
    NoCompany,
  },
  methods: {
    upalert(variant, message) {
            this.$emit('alert', variant, message);
    },
    doRot(){
      this.rot += 2;
      this.color='000000'; //this.hslToHex(this.rot, 90, 50);
    },
    hslToHex: (h, s, l) => {
      l /= 100;
      const a = s * Math.min(l, 1 - l) / 100;
      const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
    reload() {
      AppliDS.getCompany()
              .then((res) => {
                this.company = res.data;
              });
      AppliDS.getPlanning()
              .then((res) => {
                console.log(res.data)
                this.data=res.data;
                this.$emit('alert', 'primary', this.$i18n.t('planning'));
              });
    },
  },
  mounted() {
    this.reload();
    setInterval(this.doRot, 100)
  },
};

</script>