import axios from 'axios';

class MarketingDS {
  getAll(site) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/marketing/products/'+site);
  }

  update(site, form) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/marketing/products/'+site, form)
  }

  doUpdate(uuid) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/marketing/update/'+uuid)
  }
}

export default new MarketingDS();
