<template>
 
  <main id="main">
  <hero-section/>

  <!-- ======= About Us Section ======= -->
  <section id="about" class="how-it-works">
    <div class="container pb-20" data-aos="fade-up" style="margin-bottom:50px;">

      <div class="section-title">
        <h2>{{ $t('about') }}</h2>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
           <a>{{ $t('about1') }}</a>.
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_1') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_2') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_3') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_4') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_5') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('about1_6') }}</li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            {{ $t('about2') }}
          </p>
          <p>
            {{ $t('about2_1') }}
          </p>
          <p>
            {{ $t('about2_2') }}
          </p>
          <a href="#" class="btn-learn-more">{{ $t('learnMore') }}</a>
        </div>
      </div>
      <p>{{ $t('bonus') }} !!!</p>
      <p>
        {{ $t('about3') }}
      </p>

    </div>

  </section>
  <!-- End About Us Section -->

  <!-- ======= How it works Section ======= -->
  <HowWork/>

        <b-modal v-if='blog' ref="blogModal" id="blogModal" size="xl" v-bind:title="blog.title" hide-footer>
            <div style='color:gray'>du {{blog.created}}</div>
            <img :src="blog.image"/>
            <p class="blog-body" v-html="blog.body"></p>
        </b-modal>

  <BlogList :edit="false"/>

  <!-- ======= Services Section ======= -->
  <section id="blog-business-intelligence-appli-Val-Loire-pme" class="why-us section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('serv') }}</h2>
        <p>{{ $t('serv1') }}</p>
        <blockquote>{{ $t('serv2') }}</blockquote>
        <p>{{ $t('serv3') }}</p>
        <br>
      <blockquote>{{ $t('serv4') }}</blockquote>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-time"></i></div>
            <h4><a href="">{{ $t('servA') }}</a></h4>
            <p>{{ $t('servA1') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-file"></i></div>
            <h4><a href="">{{ $t('servB') }}</a></h4>
            <p>{{ $t('servB1') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-tachometer"></i></div>
            <h4><a href="">{{ $t('servC') }}</a></h4>
            <p>{{ $t('servC1') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-calendar"></i></div>
            <h4><a href="">{{ $t('servD') }}</a></h4>
            <p>{{ $t('servD1') }}</p>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Skills Section ======= -->
  <section id="skills" class="which-clients">
    <div class="container" data-aos="fade-up">
      <div class="section-title">

      <h2 class="text-center">{{ $t('who') }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
          <!-- a concept art of a commerce street with artisan, restaurant, museum, Poster illustration, natural lighting, very detailed (832x512) -->
          <img src="../../public/skills.png" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
          <p class="fst-italic">{{ $t('who1') }}</p>

          <div class="skills-content">

            <div class="progress">
              <span class="client">{{ $t('whoA') }} </span>

              <div class="">
                  <p>{{ $t('whoA1') }}</p>
            </div>
            </div>

            <div class="progress">
              <span class="client">{{ $t('whoB') }}</span>

              <div class="">
                  <p>{{ $t('whoB1') }}</p>
            </div>
            </div>

            <div class="progress">
              <span class="client">{{ $t('whoC') }}</span>
              <div class="">
                  <p>{{ $t('whoC1') }}</p>
            </div>
            </div>

            <div class="progress">
              <span class="client">{{ $t('whoD') }}</span>
              <div class="">
                  <p>{{ $t('whoD1') }}</p>
            </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </section>
  <!-- End Skills Section -->

  <!-- ======= Cta Section ======= -->
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">

      <div class="row">
        <div class="col-lg-9 text-center text-lg-start">
          <h3>{{ $t('where') }}</h3>
          <p class="nl">{{ $t('whereA') }}</p>
        </div>

      </div>

    </div>
  </section>
  <!-- End Cta Section -->

  <!-- ======= Pricing Section ======= -->
  <section id="pricing" class="pricing">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('howmuch') }}</h2>
      </div>

      <div class="row">

        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="box">
            <h3>{{ $t('forfait1') }}</h3>
            <h4><sup>€</sup>0<span>{{ $t('forfaitD1') }}</span></h4>
          
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('forfaitI1') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitJ1') }}</li>
              <li class="na"><i class="bx bx-x"></i> <span>{{ $t('forfaitK1') }}</span></li>
              <li class="na"><i class="bx bx-x"></i> <span>{{ $t('forfaitL1') }}</span></li>
            </ul>
            <a class="buy-btn" href="mailto:support@bizzo.pro?subject=Forfait gratuit Bizzo&body=Bonjour, Je suis intéressé par le forfait gratuit Bizzo">S'inscrire</a>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
          <div class="box featured">
            <h3>{{ $t('forfait2') }}</h3>
            <h4><sup>€</sup>12.00<span>{{ $t('forfaitD2') }}</span></h4>
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('forfaitI2') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitJ2') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitK2') }}</li>
            </ul>
            <a class="buy-btn" href="mailto:support@bizzo.pro?subject=Forfait petit commerce Bizzo&body=Bonjour, Je suis intéressé par le forfait petit commerce Bizzo">S'inscrire</a>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
          <div class="box">
            <h3>{{ $t('forfait3') }}</h3>
            <h4><span>{{ $t('forfaitD3') }}</span></h4>
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('forfaitI3') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitJ3') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitK3') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('forfaitL3') }}</li>
            </ul>
            <a class="buy-btn" href="mailto:support@bizzo.pro?subject=Forfait tout compris Bizzo&body=Bonjour, Je suis intéressé par le forfait tout compris Bizzo">S'inscrire</a>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!-- End Pricing Section -->

  <!-- ======= Frequently Asked Questions Section ======= -->
  <section id="faq" class="faq section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('help') }}</h2>
        <!--p>Contactez notre support, il vous répondra dans les 24h : <a href='mailto:support@bizzo.pro'>support@bizzo.pro</a></p-->
      <p>{{ $t('helpA') }}</p>
      </div>

      <div class="faq-list">
        <ul>
          <li data-aos="fade-up" data-aos-delay="100">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">{{ $t('helpQ1') }}<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
              <p>
                {{ $t('helpQA1') }}
              </p>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </section>
  <!-- End Frequently Asked Questions Section -->

  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('team') }}</h2>
        <p>{{ $t('teamA') }} <a href="https://myconnectech.fr/">MyConnecTech</a>.</p>
      </div>

      <div class="row">

        <div class="col-lg-4 mt-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="../../public/Emmanuel Goudot.png" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Emmauel</h4>
              <span>{{ $t('team1r') }}</span>
              <p>{{ $t('team1') }}</p>
              <div class="social">
                <!--<a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                <a href=""><i class="ri-instagram-fill"></i></a>-->
                <a href="https://www.linkedin.com/in/emmanuel-goudot-78614515/"> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="../../public/Angele.png" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Angèle</h4>
              <span>{{ $t('team2r') }}</span>
              <p>{{ $t('team2') }}</p>
              <div class="social">
                <!--<a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                <a href=""><i class="ri-instagram-fill"></i></a>-->
                <a href="https://www.linkedin.com/in/ang%C3%A8le-goudot-b86159152/"> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="../../public/Vicleche.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Viclèche</h4>
              <span>{{ $t('team4r') }}</span>
              <p>{{ $t('team4') }}</p>
              <div class="social">
                <!--<a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                <a href=""><i class="ri-instagram-fill"></i></a>-->
                <a href="https://www.linkedin.com/in/vicleche-moulié-09a340217/"> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 mt-4" data-aos="zoom-in" data-aos-delay="200">
        </div>

        <div class="col-lg-4 mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="Adile.png" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Adile</h4>
              <span>{{ $t('team3r') }}</span>
              <p>{{ $t('team3') }}</p>
              <div class="social">
                <!--<a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>-->
                <a href="https://www.linkedin.com/in/adile-el-haddik-044361224/"> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!-- End Team Section -->


  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('contactS') }}</h2>
        <p>{{ $t('contactSA') }}</p>
      </div>

      <div class="row">

        <div class="col-lg-5 d-flex align-items-stretch">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Localisation:</h4>
              <p>12 rue Sainte Catherine 41000 Blois</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p><a href="mailto:Emmanuel.Goudot@myConnecTech.fr">Emmanuel.Goudot@myConnecTech.fr</a></p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>{{ $t('phone') }}:</h4>
              <p>06 85 67 98 39</p>
            </div>


            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.9542980683254!2d1.3236769768330077!3d47.58813098925204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e357e2b74cf175%3A0xfc340f4a1e3af927!2s12%20Rue%20Sainte-Catherine%2C%2041000%20Blois!5e0!3m2!1sfr!2sfr!4v1682517444520!5m2!1sfr!2sfr" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
          </div>

        </div>

        <div class="col-lg-7 mt-5 mt-lg-0 Xd-flex align-items-stretch" style="display:none">
          <!--form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">Votre Nom</label>
                <input type="text" name="name" class="form-control" id="name" required>
              </div>
              <div class="form-group col-md-6">
                <label for="name">Votre Email</label>
                <input type="email" class="form-control" name="email" id="email" required>
              </div>
            </div>
            <div class="form-group">
              <label for="name">Sujet</label>
              <input type="text" class="form-control" name="subject" id="subject" required>
            </div>
            <div class="form-group">
              <label for="name">Message</label>
              <textarea class="form-control" name="message" rows="10" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Chargement</div>
              <div class="error-message"></div>
              <div class="sent-message">Votre message à bien été envoyé. Merci!</div>
            </div>
            <div class="text-center"><button type="submit">Envoyez</button></div>
          </form-->
        </div>

      </div>

    </div>
  </section>
<!-- ======= Footer ======= -->
<footer id="footer">

<!-- <div class="footer-newsletter">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <h4>Rejoindre notre Newsletter</h4>
        <p>Rejoignez-nous et profitez des dernières information et avancées de notre application</p>
        <form action="" method="post">
          <input type="email" name="email"><input type="submit" value="Souscrire">
        </form>
      </div>
    </div>
  </div>
</div> -->

<div class="footer-top">
  <div class="container">
    <div class="row">

      <div class="col-lg-3 col-md-6 footer-contact">
        <h3>Bizzo</h3>
        <p>
          12 rue Sainte Catherine <br>
          41000 Blois <br>
          France <br><br>
          <strong>Phone:</strong> 06 85 67 98 39<br>
          <strong>Email:</strong> Emmanuel.Goudot@myConnecTech.fr<br>
        </p>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
        <h4>{{ $t('usefull') }}</h4>
        <ul>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Comment ?</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Pourquoi ?</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Qui ?</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Nous Contacter</a></li>
        </ul>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
        <h4>{{ $t('services') }}</h4>
        <ul>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Timeline</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Application</a></li>
         
        </ul>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
        <h4>{{ $t('social') }}</h4>
        <p>Suivez Nous!</p>
        <div class="social-links mt-3">
        <!--
          <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
          <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
          -->
          <a href="https://www.linkedin.com/company/25413825/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container footer-bottom clearfix">
  <div class="copyright">
    &copy; Copyright <strong><span>BizzO</span></strong>. {{ $t('rights') }}
  </div>

</div>
</footer><!-- End Footer -->
  
  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- End Contact Section -->
  
  <!--h2 id="partage">Partagez</h2>
  
  
  
  <img src="bizzoQR.png" width="200" height="200" style="margin: 20px;"/-->
</main><!-- End #main -->

</template>

<script>
import HeroSection from '@/components/HeroSection.vue'
import HowWork from '@/components/HowWork.vue'
import BlogList from '@/components/BlogList.vue'
import BlogDS from "../services/BlogDS";

export default {
  name: 'HomePage',
  components: {
    HeroSection,
    HowWork,
    BlogList,
  },
  data() {
      return {
          blog: {title:null},
      }
    },
  methods: {
    clickBlog(uuid) {
      BlogDS.get(uuid)
      .then((res)=>{
            this.blog=res.data
            this.$refs.blogModal.show();
      });
    },
  }
}
</script>

<style scoped>
:root {
  --couleur-principale: #2aa75a;
  --couleur-secondaire:#7d4ffe ;
  --couleur-tertiaire: #96e8b5;
  --couleur-texte: #444444;
}
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: var(--couleur-principale);
  text-decoration: none;
}

a:hover {
  color: var(--couleur-tertiaire);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}

p.nl {white-space:pre;}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--couleur-principale);
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--couleur-principale);;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--couleur-secondaire);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6bc1e9;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--couleur-principale);;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--couleur-secondaire);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
section .how-it-works{
  padding: 115px 0 115px 0 ;
  overflow: hidden;
}

.how-it-works .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.how-it-works .content ul {
  list-style: none;
  padding: 0;
}

.how-it-works .content ul li {
  padding-left: 28px;
  position: relative;
}

.how-it-works .content ul li+li {
  margin-top: 10px;
}

.how-it-works .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color:var(--couleur-secondaire);
  line-height: 1;
}

.how-it-works .content p:last-child {
  margin-bottom: 0;
}

.how-it-works .content .btn-learn-more {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color:var(--couleur-secondaire);
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid var(--couleur-secondaire);
}

.how-it-works .content .btn-learn-more:hover {
  background: var(--couleur-secondaire);
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.end-content h3{
  padding: 0 0 50px 100px;
  color: var(--couleur-principale);
}


/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.why-us .icon-box .icon {
  margin-bottom: 10px;
}

.why-us .icon-box .icon i {
  color:var(--couleur-secondaire);
  font-size: 36px;
  transition: 0.3s;
}

.why-us .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.why-us .icon-box h4 a {
  color:var(--couleur-principale);
  transition: ease-in-out 0.3s;
}

.why-us .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.why-us .icon-box:hover {
  transform: translateY(-10px);
}

.why-us .icon-box:hover h4 a {
  color: var(--couleur-secondaire);
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
    /* margin-top: 30px; */
    margin-bottom: none;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.which-clients .content h3 {
  font-weight: 700;
  font-size: 32px;
  color:var(--couleur-principale);
  font-family: "Poppins", sans-serif;
}

.which-clients .content ul {
  list-style: none;
  padding: 0;
}

.which-clients .content ul li {
  padding-bottom: 10px;
}

.which-clients .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color:var(--couleur-secondaire);
}

.which-clients .content p:last-child {
  margin-bottom: 0;
}

.which-clients .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.which-clients .progress .client {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color:var(--couleur-principale);
}

.which-clients .progress .client .val {
  float: right;
  font-style: normal;
}

.which-clients .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}

.which-clients .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: var(--couleur-secondaire);
}


/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(6, 137, 14, 0.9), rgba(38, 110, 65, 0.9)), url("../../public/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: var(--couleur-secondaire);
  border: 2px solid var(--couleur-secondaire);
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 20px;
  color: var(--couleur-principale);
}

.pricing h4 {
  font-size: 48px;
  color:var(--couleur-principale);
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: var(--couleur-secondaire);
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pricing ul i {
  color: var(--couleur-principale);
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  color:var(--couleur-secondaire);
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid var(--couleur-secondaire);
}

.pricing .buy-btn:hover {
  background:var(--couleur-secondaire);
  color: #fff;
}

.pricing .featured {
  border-top-color:var(--couleur-secondaire);
}

.pricing .featured .buy-btn {
  background: var(--couleur-secondaire);
  color: #fff;
}

.pricing .featured .buy-btn:hover {
  background:var(--couleur-secondaire);
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color:var(--couleur-secondaire);
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: var(--couleur-principale);
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: var(--couleur-secondaire);
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}


/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
  height: 100%;
}

.team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover {
  transform: translateY(-10px);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: var(--couleur-principale);
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}

.team .member .social a i {
  color: var(--couleur-principale);
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: var(--couleur-secondaire);
}

.team .member .social a:hover i {
  color: #fff;
}

.team .member .social a+a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid var(--couleur-secondaire);
  border-bottom: 3px solid var(--couleur-secondaire);
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: var(--couleur-secondaire);
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--couleur-principale);
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color:var(--couleur-secondaire);
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background:var(--couleur-secondaire);
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: var(--couleur-secondaire);
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid var(--couleur-secondaire);
  border-bottom: 3px solid var(--couleur-secondaire);
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #47b2e4;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #209dd8;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: var(--couleur-principale);
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: #444444;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: var(--couleur-principale);
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--couleur-secondaire);
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background:var(--couleur-secondaire);
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--couleur-principale);
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Jost", sans-serif;
  color: #5e5e5e;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--couleur-principale);
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: var(--couleur-secondaire);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: var(--couleur-secondaire);
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background:var(--couleur-secondaire);
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: var(--couleur-secondaire);
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

#footer .copyright {
  float: left;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }
}


</style>

