import axios from 'axios';

class BlogDS {
  getAll(all) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/blog/list?all='+all);
  }

  delete(uuid) {
    return axios.delete(process.env.VUE_APP_ROOT_API+'/blog/blog/'+uuid);
  }

  get(uuid) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/blog/blog/'+uuid)
  }

  update(uuid, data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/blog/blog/'+uuid, data)
  }
}

export default new BlogDS();
