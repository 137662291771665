<style>

  @import 'vis-timeline/styles/vis-timeline-graph2d.min.css';

  div#graph2d {
    margin-left: 85px; /* pour être calé sur le timeline */
  }

  div.timeline {
    color: black;
    margin-top: 88px;
    }
  .vis-item.jf {
      font-size: 60%;
      color: gray;
      background-color: white;
      border-color: black;
  }
  .vis-item.TRAVAUX {
      background-color: red;
  }
  .vis-item.CLOSE {
      background-color: gray;
  }
  .vis-item.MARKETING {
      background-color: green;
  }
  .vis-item.conge {
      font-size: 60%;
      color: black;
      background-color: lightgreen;
      border-color: black;
  }
  .vis-item.meteo {
      font-size: 60%;
      color: black;
      background-color: red;
      border-color: black;
  }

  .sr-only {display: none;}

  /* alternating column backgrounds */
  .vis-time-axis .vis-grid.vis-odd {
    background: #f5f5f5;
  }

  /* gray background in weekends, white text color */
  .vis-time-axis .vis-grid.vis-saturday,
  .vis-time-axis .vis-grid.vis-sunday {
    background: gray;
  }
  .vis-time-axis .vis-text.vis-saturday,
  .vis-time-axis .vis-text.vis-sunday {
    color: white;
  }
</style>

<template>
  <div class="timeline">
    <h2>Timeline</h2>

    <!-- <b-button @click="addEvent" class='' variant="info">{{ $t('timelineAddEvent') }}</b-button> -->

    <b-form @submit="onSubmit" @reset="onReset" v-if="eg">
      <div class="container">
        <h3>{{eg.name}} [{{eg.id}}]</h3>
        <div v-for="(evt,i) in eg.events" :key="i">
          <div class="row mb-0">
              <div class="col-sm">
                <b-input-group id="input-group-2" class="mb-1" >
                                <b-input-group-text >Début</b-input-group-text>
                                <b-form-input id="input-2t" v-model="evt.start" type="text" placeholder="YYYY-MM-DD" autocomplete="off" required></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-model="evt.start" button-only right locale="fr-FR" aria-controls="input-2t"></b-form-datepicker>
                                </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm">
                <b-input-group id="input-group-2" class="mb-1" >
                                <b-input-group-text >Fin</b-input-group-text>
                                <b-form-input id="input-2t" v-model="evt.end" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-model="evt.end" button-only right locale="fr-FR" aria-controls="input-2t"></b-form-datepicker>
                                </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm">
                  <b-input-group id="input-group-1" label="Description:" label-for="input-1" class="mb-1">
                    <b-input-group-text >Description</b-input-group-text>
                    <b-form-input id="input-1" v-model="evt.comment" type="text" placeholder="Description"></b-form-input>
                  </b-input-group>
              </div>
          </div>
        </div>
         <b-button-group class="mb-2">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button @click="onAddDate()" type="button" variant="secondary">Ajout date</b-button>
          <b-button @click="eg=null;" type="cancel" variant="danger">Cancel</b-button>
          <!--<b-button type="delete" variant="danger">Delete</b-button>-->
          <!--<b-button type="reset" variant="danger">Reset</b-button>-->
         </b-button-group>
      </div>
    </b-form>


    <b-form @submit="onSubmit" @reset="onReset" v-if="item">
      <div class="container">
      <h3>Edition d'un évènement</h3>
        <div class="row mb-3">
            <div class="col-sm">
              <b-input-group id="input-group-1" label="Description:" label-for="input-1">
                <b-input-group-text >Description</b-input-group-text>
                <b-form-input id="input-1" v-model="item.content" type="text" placeholder="Description" required></b-form-input>
              </b-input-group>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm">
              <b-input-group id="input-group-2" class="mb-3" >
                <b-input-group-text >Début</b-input-group-text>
                <b-form-input id="input-2t" v-model="item.start" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker v-model="item.start" button-only right locale="fr-FR" aria-controls="input-2t"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-sm">
             <b-input-group id="input-group-3" label="Date fin:" label-for="input-3" >
                <b-input-group-text >Fin</b-input-group-text>
                <b-form-input id="input-3" v-model="item.end" style="width: 100px" type="text" placeholder="YYYY-MM-DD" autocomplete="off"/>
                <b-input-group-append>
                    <b-form-datepicker v-model="item.end" button-only right aria-controls="input-3"></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>

            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm">
              <b-input-group id="input-group-4" label="Type:" label-for="input-4" description="Type d'évènement.">
                <b-input-group-text >Type</b-input-group-text>
                <b-form-select v-model="item.className" required>
                    <option v-for="(x,i) in classNames" :key="i" :value="x.val">{{x.txt}}</option>
                </b-form-select>
              </b-input-group>
            </div>
        </div>

        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="delete" variant="danger">Delete</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </div>
    </b-form>

    <div>
    Sites : <select id='site' @change='siteChange'>
        <option v-for="(site,is) in company.sites" :key="is" :value="site.id">{{site.name}} {{site.insee}} {{site.adresse_complete}} {{site.SIRET}}</option>
      </select>
      <br/>
    {{ $t('indicator') }} : <select id='indicator' @change='indicChange'><option v-for="(indic,k) in indicators" :key="k" :value="indic.id">{{indic.name}}</option></select>
    </div>

    <div ref="timeline"></div>
    <div id="graph2d" ref="graph2d"></div>


  </div>
</template>

<script>
import AppliDS from "../services/AppliDS";

const vis = require('vis-timeline/standalone');

// https://www.npmjs.com/package/vue-visjs
// https://visjs.github.io/vis-timeline/docs/timeline/

// https://jsfiddle.net/api/post/library/pure/

export default {
  name: 'TimelineView',
  props: ['login'],
  components: {
  },
  computed: {
  },
  data() {
    return {
      tl: null,
      indicators: null,
      company: {sites:[]},

        graph2d: {
              obj: null,
              groups: [
                {id:'val', content:'Valeur ', options:{drawPoints:{style:'square', size:6}}},
                {id:'pred', content:'Prédiction', options:{drawPoints:{style:'circle'}}},
              ],
              items: [
              ],
              options: {
                width: '100%',
                height: '400px',
                //defaultGroup: 'ungrouped',
                legend: true,
                interpolation: {parametrization: 'centripetal'},
                locale: 'fr',
                start: '2023-01-01',
                end: '2023-06-01',
                //onMove: function(/*item,callback*/){console.log('MOVE !');}
              },
            },

      item: null, // Edition d'un item
      eg: null, // Edition d'un group d'Events
      info: '-',
      classNames: [{val: 'CLOSE', txt:'Fermeture'},
        {val: 'MARKETING', txt:'Marketing'},
        {val: 'TRAVAUX', txt:'Travaux'},
      ],
      classNamesAdmin: [
        {val: 'event', txt:'Event'},
        {val: 'braderie', txt:'Braderie'},
        {val: 'closed', txt:'Fermeture'},
        {val: 'marketing', txt:'Marketing'},
        {val: 'travaux', txt:'Travaux'},
        {val: 'jf', txt:'Jour Férié'},
        {val: 'conge', txt:'Congés'},
      ],


      timeline: {
          obj: null,
          groups: [{id: '1',content: 'TBD'}],
          items: null,
          options: {
            width: '100%',
            height: '400px',
            snap: function (date) {
                  var jour = 24 * 60 * 60 * 1000;
                  return Math.round(date / jour) * jour;
                },
            //editable:{remove: true},
            /*editable: {
                add: true,         // add new items by double tapping
                updateTime: true,  // drag items horizontally
                updateGroup: true, // drag items from one group to another
                remove: true,       // delete an item by tapping the delete button top right
                //overrideItems: false  // allow these options to override item.editable
              },*/
            //selectable: true,
            locale: 'fr',
            start: new Date(),
            end: new Date('2023-12-31'),
            //onRemove: this.onRemove,
            //editable: true,
          }
      }
    }
  },
  methods: {
    indicChange(evt) {
        console.log('indicChange', evt.target.value);
        AppliDS.getIndicator(evt.target.value)
            .then((res) => {
                    let items=[];
                    res.data.values.forEach(x=>{
                        if (x.val!=null) items.push({'x':x.date, 'y':x.val, 'group':'val'});
                        if (x.pred!=null) items.push({'x':x.date, 'y':x.pred, 'group':'pred'});
                        })
                    this.graph2d.items=items;
                    this.graph2d.obj.setItems(this.graph2d.items);
        });
    },
    siteChange(evt) {
        let site=evt.target.value;
        console.log('siteChange', site);
        AppliDS.getIndicatorBySite(site)
          .then((res) => {
            this.indicators = res.data;
            this.indicChange({target:{value: this.indicators[0].id}});
            this.$emit('alert', 'secondary', res.data.length+' indicateurs');
        });
        AppliDS.getEventsBySite(site)
            .then((res) => {
                console.log('EVENTS', res.data);
                this.$emit('alert', 'secondary', this.$i18n.t('evtgrp')+' : '+res.data.eg.length); // TODO caché par graph
                let items=[];
                res.data.eg.forEach((eg, egi)=>{
                    console.log('event group', egi, eg);
                    eg.events.forEach(e=>{
                        //console.log('  event', e);
                        let x={id: e.id,
                               start:new Date(e.start),
                               content: eg.name,
                               group: eg.group,
                               eg: egi,
                               className: eg.class, // pour les classes
                               title:eg.name+(e.comment ? ' : '+e.comment : '')+', '+e.start+' à '+e.end,
                               };
                        if (e.end!=e.start) x.end=new Date(e.end);
                        items.push(x)
                    });
                });
                console.log('items', items);
                this.timeline.groups=res.data.groups;
                this.timeline.items=items; // NEW
                this.timeline.eg=res.data.eg;
                if (this.login.role==1) {
                    this.classNames=this.classNamesAdmin
                }

                this.timeline.obj.setData({groups: this.timeline.groups, items: this.timeline.items});
        });
    },
    onAddDate() {
        this.eg.events.push({start: '', end: '', comment:'', id:null})
    },
    onSubmit(evt){
        //alert('sauvegarde !')
        evt.preventDefault();
        console.log('save', this.eg)
        AppliDS.saveEvent(this.eg)
                          .then((res) => {
                            // TODO PAS OK
                            // MaJ id de l'event
                            this.eg=null;
                            console.log(res.data); // {id: XXX, status:"OK"}
                            let it=this.timeline.items.find(x => x.id==this.item.id)
                            it.id = res.data.id
                            this.item = null;
                          });
    },
    onReset(){
        this.item=null;
    },
    addEvent() {
        let item={classname:'', content:'', start:new Date().toISOString().substring(0,10), id:'new'+this.timeline.items.length, group:'1'}
        this.timeline.items.push(item);
        this.item = item;
    },
    onSelect(evt) {
        let item = this.timeline.items.find(x => x.id==evt.items[0])
        if ((this.login.role==1)||(item && item.group=='1')) {
            item.start=item.start.toISOString().substring(0,10)
            item.end=item.end.toISOString().substring(0,10)
            this.item = item;
            }
        else this.item=null;
        console.log('onSelect', evt.items, this.item);
    },
    onRemove(item, callback){
        if (item.group=='1') {
            if (confirm('Suppression ?')) {
                console.log('supp');
                AppliDS.deleteEvent(item.id);
            } else item=null;
            this.info+=',remove'
            console.log('onRemove', item, callback);
            } else item=null;
        callback(item); // confirm deletion
    },
  },
  mounted() {
    console.log('mounted', this.timeline);

    var items = new vis.DataSet([]);
    var items2d = new vis.DataSet([]);

    let timeline = new vis.Timeline(this.$refs.timeline, items, this.timeline.options);
    console.log('timeline options', this.timeline.options)
    this.timeline.obj = timeline;

    let graph2d = new vis.Graph2d(this.$refs.graph2d, items2d, this.graph2d.options);
    this.graph2d.obj = graph2d;

    console.log('timeline', this.timeline.obj, this.graph2d.obj);

    this.timeline.obj.on('rangechanged', range => {
      graph2d.setWindow(range.start, range.end, {animation: false});
      let gl=document.getElementsByClassName('vis-left');
      let gr=document.getElementById('graph2d');
      let decal = gl[0].clientWidth - gl[1].clientWidth;
      gr.style['margin-left'] = decal+'px';
    });
    this.graph2d.obj.on('rangechanged', range => {
      timeline.setWindow(range.start, range.end, {animation: false});
      let gl=document.getElementsByClassName('vis-left');
      let gr=document.getElementById('graph2d');
      let decal = gl[0].clientWidth - gl[1].clientWidth;
      gr.style['margin-left'] = decal+'px';
    });

    this.timeline.obj.on('click', properties => {
        if (properties.item) {
            console.log('click', properties);
            var eg=this.timeline.eg.filter((grp) => grp.events.filter((evt) => evt.id==properties.item).length>0);
            console.log('EventGroup', eg);
            this.eg = eg[0];
            }
    });


    },
  created() {
    console.log('created');
    AppliDS.getCompany()
      .then((res) => {
        this.company = res.data;
        this.siteChange({target:{value: this.company.sites[0].id}});
    });
  },
}
</script>
