<template>
  <div class="marketing">

    <b-modal id="modal-slideshow" size="xl" scrollable :title="'Slideshow '+site_uuid">
      lien <a :href="this.URL+'/marketing/messages/'+this.site_uuid">slideshow</a>
      <div >
      <iframe title="slideshow" width="900" height="1440" :src="this.URL+'/marketing/messages/'+this.site_uuid" />
      </div>
    </b-modal>

    <h1>Marketing</h1>
    Sites : <select id='site' @change='siteChange'>
        <option v-for="(site,is) in company.sites" :key="is" :value="site.id">{{site.name}} {{site.insee}} {{site.adresse_complete}} {{site.SIRET}}</option>
      </select>

            <!--<div>products: {{products}}</div>-->


    <b-form id="myForm" ref="myForm">
    <vue-good-table
          :columns="columns"
          :rows="products"
          :search-options="searchOptions"
          styleClass="vgt-table striped bordered">

          <div slot="table-actions">
              <b-button @click="addProduct" variant="secondary">Ajout produit</b-button>
              <b-button ref="saveButton" @click="onSubmit" variant="primary">Enregistrer</b-button>
              <b-button ref="generateButton" @click="onGenerate" variant="warning">Générer messages</b-button>
              <b-button v-b-modal.modal-slideshow variant="success">Slideshow</b-button>
          </div>
          <div slot="table-actions-bottom">
              <b-button @click="addProduct" variant="secondary">Ajout produit</b-button>
              <b-button @click="onSubmit" variant="primary">Enregistrer</b-button>
          </div>
          <div slot="emptystate">
              Cliquez sur 'Ajout produit' pour ajouter des produits
          </div>

            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'uuid'">{{props.formattedRow[props.column.field]}}</span>
                <span v-if="props.column.field == 'description'">
                    <b-form-textarea @change="myChange(props)" v-model="props.row[props.column.field]" type="text" rows="3"/>
                    <pre>{{props.row.message}}</pre>
                </span>
                <b-input v-if="props.column.field == 'avail'" @change="myChange(props)" v-model="props.row[props.column.field]" type="number" />
                <b-input v-if="props.column.field == 'ref'" @change="myChange(props)" v-model="props.row[props.column.field]" type="text" />
                <b-img v-if="props.column.field == 'imageXXX'" thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
                <div v-if="props.column.field == 'image'">
                    <!-- https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/ -->
                    <div :ref="'dropArea'+props.index" class="drop-area" :data-idx="props.index"
                        @dragenter="highlight" @dragover="highlight"
                        @dragleave="unhighlight" @drop="handleDrop">
                        <img :id="'dropImg'+props.index" :ref="'dropImg'+props.index" :src="props.row.src"/>
                        <input type="file" :id="'fileElem'+props.index" :ref="'fileElem'+props.index" class="fileElem" accept="image/*"
                            @change="fileChange">
                        <label class="button" :for="'fileElem'+props.index">Image</label>
                        <!--<div ref="gallery" id="gallery"></div>-->
                    </div>
                    <!--<progress ref="progressBar" id="progress-bar" max=100 value=0></progress>-->
                </div>
                 <span v-if="props.column.field == 'action'">
                  <b-button @click="addProduct" variant="primary">Sauve</b-button>
                </span>
            </template>
    </vue-good-table>
    </b-form>

  </div>
</template>

<style scoped>
.marketing {margin-top: 73px;}

.prompt {background-color: antiquewhite;}
.answer {background-color: lightgreen;}

img {
    max-width: 300px;
    max-height: 300px;
}


.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  //width: 480px;
  font-family: sans-serif;
  //margin: 100px auto;
  padding: 20px;
}
.drop-area.highlight {
  border-color: purple;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
.fileElem {
  display: none;
}

</style>

<script>
//import config from '../../config.yml'
import MarketingDS from "../services/MarketingDS";
import AppliDS from "../services/AppliDS";

export default {
  props: ['login'],
  data() {
      return {
        company: {sites:[]},
        products: [], //[{"ref": "Ref1.0", "uuid":"001122335588", "description": "Description"}],
        columns: [
            {label: 'Ref', field: 'ref', width: '150px',},
            //{label: 'UUID', field: 'uuid',},
            {label: 'Description', field: 'description',},
            {label: 'Disponibilité', field: 'avail', width: '150px'},
            {label: 'Image', field: 'image', width: '400px', sortable: false},
            //{label: 'Actions', field: 'action',},
        ],
        searchOptions: {enabled: true,},
        files_array: [], // les files images

        chat: {prompt: "TBD"},

        data: null,
        site: null, site_uuid: null,
        file: null,
        chatGPT: null,
        //products: '?',
        URL: process.env.VUE_APP_ROOT_API, //config.URL,
        market: {
            columns: [
            {label: 'Id', field: 'id',},
            {label: 'Description', field: 'description',},
            ],
          rows: [],
        },
      };
    },
  name: 'MarketingView',
  components: {
  },
  methods: {
    onGenerate() {
        this.$refs.generateButton.disabled=true;
        this.$refs.saveButton.disabled=true;
        this.site_uuid=this.company.sites.find(s => s.id==this.site).uuid;
        MarketingDS.doUpdate(this.site_uuid)
            .then(() => {
                this.$emit('alert', 'primary', 'Messages mis à jour');
                this.$refs.generateButton.disabled=false;
                this.$refs.saveButton.disabled=false;
                this.siteChange({target:{value: this.site}});
            })
    },
    onSlideshow() {
    },
    onSubmit() {
        //alert('submit');
        this.$refs.saveButton.disabled=true;
        let form = new FormData();
        //form.append("products", new Blob([JSON.stringify(this.products)], {type: 'application/json'}));
        form.append("products", JSON.stringify(this.products));
        //form.append('test', 'OK');
        this.products.forEach((x,i)=>{
            if (this.files_array[i]) {
                //form.append("file"+i, this.files_array[i], this.files_array[i].name);
            }
        });
        MarketingDS.update(this.site, form)
        .then((res) => {
            console.log('onSubmit', res);
            this.$refs.saveButton.disabled=false;
            this.$emit('alert', 'primary', this.$i18n.t('saveddata')+' : '+res.data);
            this.siteChange({target:{value: this.site}});
        });

    },
    myChange(props) {
        //console.log('myChange', props);
        //console.log('myChange+', this.products, props.index, props.column.field);
        this.products[props.index][props.column.field] = props.row[props.column.field];
    },
    fileChange(evt){
        let target=evt.target;
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        console.log('fileChange', target, target.dataset.idx, evt.target.files);
        this.handleFiles(evt.target.files, target.dataset.idx);
    },
    handleFiles(files, idx){
        //console.log('handleFiles', files);
        files = [...files]
        console.log('handleFiles', files, idx, this.products);
        files.forEach((f) => {
            console.log('handleFiles++', this.products);
            this.previewFile(f, idx);
            this.files_array[idx]=f;
        });
    },
    previewFile(file, idx) {
        console.log('previewFile', file, this.products);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => this.products[idx].src=reader.result;
    },
    preventDefaults(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    },
    highlight(evt){
        let target=evt.target;
        //console.log('highlight', target);
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        target.classList.add('highlight');
        this.preventDefaults(evt);
    },
    unhighlight(evt){
        let target=evt.target;
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        target.classList.remove('highlight');
        this.preventDefaults(evt);
    },
    handleDrop(evt) {
        let target=evt.target;
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        console.log('handleDrop', evt.target, this.products);
        let dt = evt.dataTransfer
        let files = dt.files
        this.handleFiles(files, target.dataset.idx)
        this.preventDefaults(evt);
    },
    siteChange(evt) {
        this.site=evt.target.value;
        this.site_uuid=this.company.sites.find(s => s.id==this.site).uuid;
        console.log('siteChange', this.site, this.site_uuid)
        MarketingDS.getAll(this.site)
        .then((res) => {
          console.log('products', res.data);
          this.products=res.data;
        });
    },
    addProduct() {
        this.products.push({"ref": "", "uuid":"", "description": "", "avail": 1, "src":""});
    },


    copyToClipboard() {
      this.$refs.products.focus();
      document.execCommand("copy");
    },
  },
  created() {
  },
  mounted() {
    console.log('MOUNT', this.config);
    AppliDS.getCompany()
        .then((res) => {
        this.company = res.data;
        this.siteChange({target:{value: this.company.sites[0].id}});
    });
  },
};
</script>
