<template>
    <div class="container">

        <BlogList v-if="loaded" :edit="login && ((login.role & 2)!=0)" /> <!-- ref="blogs" addBlog="true" published="all" -->

    </div>
</template>

<script>
import LoginDS from "../services/LoginDS";
import BlogList from '@/components/BlogList.vue'

export default {
  name: 'BlogView',
  data() {
    return {
        login: null,
        loaded: false,
        blogs: null,
        blog: null,
        image: '',
    }
  },
  components: {
      BlogList,
  },
  methods: {
  },
  mounted() {
    LoginDS.get().then((res) => {this.login=res.data; this.loaded=true;});
  },
}

</script>

<style scoped>
div.container{
  margin-top: 130px;
}
</style>
