import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/Home.vue'
import GraphView from '../views/Application.vue'
import PlanningView from '../views/Planning.vue'
import AdminView from '../views/Admin.vue'
import MarketingView from '../views/Marketing.vue'
import FacebookView from '../views/Facebook.vue'
import TimelineView from '../views/Timeline.vue'
import MentionsLegales from '../views/MentionsLegales.vue'
import CGV from '../views/CGV.vue'
import BlogFeed from '../views/BlogFeed.vue'
//import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/app',
    name: 'Application',
    component: PlanningView
  },
  {
    path: '/graph',
    name: 'Graphiques',
    component: GraphView
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView
  },
  {
    path: '/marketing',
    name: 'MarketingView',
    component: MarketingView
  },
  {
    path: '/facebook',
    name: 'FacebookView',
    component: FacebookView
  },
  {
    path: '/timeline',
    name: 'TimelineView',
    component: TimelineView
  },
  {
    path: '/blog-business-intelligence-appli-Val-Loire-pme/:id?',
    name: 'BlogView',
    component: BlogFeed
  },
  {
    path: '/cgv',
    name: 'CGV',
    component: CGV
  },
  {
    path: '/mentions',
    name: 'MentionsLegales',
    component: MentionsLegales
  },
  /*
  {
    path: '/about',
    name: 'About',
    component: About
  },
    */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
