<template>
  <div class="facebook">
    <h3>Contacts</h3>

    <b-button variant="secondary" @click="contactAdd">Add contact</b-button>
    <b-button variant="primary" @click="contactSave">Sauver</b-button>
    <table class="contacts">
      <thead>
        <tr>
          <th v-for="(cat, i) in categories" :key="i" scope="col">{{cat}}</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td v-for="(cat, i) in categories" :key="i">
                <div class="drop-area" id="dropzone0" @drop="onDrop($event, cat)" @dragover.prevent @dragenter.prevent>
                    <span v-for="(contact, i) in contacts" :key="i" >
                        <div v-if="contact.type1 == cat" :class="'contact draggable d-inline-flex p-2 '+contact.type1" draggable @click="clickContact(contact)" @dragstart="startDrag($event, contact)">{{contact.surname}} {{contact.name}} ({{contact.email}})</div>
                    </span>
                </div>
            </td>
          </tr>
      </tbody>
    </table>

    <div ref="mynetwork" id="mynetwork"></div>

    <b-modal ref="contactModal" id="contactModal" title="Contact" hide-footer>
      <b-form @submit="onSubmitContact" @reset="onReset" class="w-100">

      <b-form-group label="Nom:">
        <b-form-input type="text" v-model="contact.name" required placeholder=""/>
      </b-form-group>
      <b-form-group label="Prénom:">
        <b-form-input type="text" v-model="contact.surname" required placeholder=""/>
      </b-form-group>
      <b-form-group label="Email:" label-for="c_email">
        <b-form-input id="c_email" type="text" v-model="contact.email" required placeholder=""/>
      </b-form-group>
      <b-form-group label="Information:">
        <b-form-textarea v-model="contact.information" type="text" rows="3"/>
      </b-form-group>
      <b-form-group label="genre:">
        <b-form-radio v-model="contact.genre" name="genre" value="m">M</b-form-radio>
        <b-form-radio v-model="contact.genre" name="genre" value="f">F</b-form-radio>
      </b-form-group>
      <b-form-group label="UUID:">
        <b-form-input type="text" v-model="contact.uuid"  readonly/>
      </b-form-group>

      <!--
      <b-form-group label="Type:" label-for="c_type">
                <b-form-input id="c_type" type="text" v-model="contact.type" required placeholder=""/>
      </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      -->

      </b-form>
    </b-modal>

  </div>
</template>

<style scoped>
.facebook {margin-top: 73px;}

h3 {color: blue;}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  //width: 480px;
  font-family: sans-serif;
  //margin: 100px auto;
  padding: 20px;

  background-color: #eee;
  min-height: 200px;
}

table.contacts {width: 100%;}
table.contacts th {width: v-bind(size);}

.draggable {
  border: 1px dashed #ccc;
  border-radius: 5px;
  //background-color: cornflowerblue;
  margin: 2px;
  text-align: center;
  }

.prescripteur {background-color: red;}
.associe {background-color: white;}
.prospect {background-color: orange;}
.customer {background-color: green;}

#mynetwork {
        width: 100%;
        height: 700px;
        border: 1px solid lightgray;
      }

</style>

<script>
import AppliDS from "../services/AppliDS";

const vis = require('vis-network/standalone');

export default {
  props: ['login'],
  data() {
      return {
        company: {sites:[]},
        categories: ["associe", "prospect", "customer", "prescripteur"],
        size: '25%', // TODO fonctionne ?
        contacts: [],
        contact: {}, // Edition

        type2: {'person': {color: "#FB7E81"},
                'company': {shape: "box", color: "#97C2FC"},
                'administration': {shape: "diamond", color: "#C2FABC"},
                },
        graph: {
          data:{
            nodes: new vis.DataSet([
                           { id: 1, label: "Node 1" },
                           { id: 2, label: "Node 2" },
                           { id: 3, label: "Node 3" },
                           { id: 4, label: "Node 4" },
                           { id: 5, label: "Node 5" },
                         ]),
            edges: new vis.DataSet([
                           { from: 1, to: 3 },
                           { from: 1, to: 2 },
                           { from: 2, to: 4 },
                           { from: 2, to: 5 },
                           { from: 3, to: 3 },
                         ]),
          },
          options: {
            interaction: { hover: true },
            manipulation: { enabled: true },
          },
        },
      };
    },
  name: 'FacebookView',
  components: {
  },
  methods: {
    startDrag(evt, contact) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('contactID', contact.uuid)
    },
    onDrop(evt, list) {
      const contactID = evt.dataTransfer.getData('contactID')
      console.log('  onDrop contactID', contactID);
      const contact = this.contacts.find((item) => item.uuid == contactID)
      contact.type = list
    },
    clickContact(contact) {
        this.contact = contact; //{...contact};
        this.$refs.contactModal.show();
    },
    contactAdd(){
        this.contacts.push({type: this.categories[0], name: '-----', surname: '', email: '', information: '', genre:'', uuid: 'new'+Math.random()})
    },
    contactSave() {
        AppliDS.updateCrm(this.contacts)
            .then((res) => {
            console.log(res.data)
            this.contacts=res.data;
        });
    },
    onSubmitContact() {
        console.log(this.contact.uuid);
        let contact = this.contacts.find((item) => item.uuid == this.contact.uuid)
        let idx = this.contacts.findIndex((item) => item.uuid == this.contact.uuid)
        console.log(contact);
        this.contacts[idx]={...this.contact}
    },
    onReset(){
    },
  },
  created() {
  },
  mounted() {
    //let timeline = new vis.Timeline(this.$refs.mynetwork, items, this.timeline.options);
    this.graph.network = new vis.Network(this.$refs.mynetwork, this.graph.data, this.graph.options);
    //console.log(network);

    AppliDS.getCrm()
        .then((res) => {
        //console.log('mounted', res.data);
        this.contacts = res.data.contacts;
        this.graph.data.edges = res.data.edges;
        this.graph.data.nodes = res.data.contacts.map(x => ({id:x.uuid,
                                                      label: x.surname+' '+x.name,
                                                      title: x.email+"\n"+x.information,
                                                      ...this.type2[x.type2]}));
        let data = {nodes: this.graph.data.nodes, edges: this.graph.data.edges};
        console.log(data);

        this.graph.network.setData(data)

      });


  },
};
</script>
