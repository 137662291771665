<style >
div {
    color: black;
    }
    h3 {color: red;}
    rect {fill:blue;}
    div.tooltip {
        background-color: lightgreen;
        border: 2px solid;
        border-radius: 5px;
        opacity: 0;
        z-index: 10;
        position: absolute;
        }
    .vis-graph-group1 {
        stroke: #f99f1c;
        stroke-width: 1px;
    }
    .vis-graph-group0 {
        stroke: #35aade;
    }

</style>

<template>
  <div :id="'indic-'+data.id">
    <h3 :title="data.id">{{data.name}} (précision {{this.accuracy}}%)
        <button v-if="false" type="button" class="btn btn-success btn-sm" @click="updIndicator">Mise à jour</button>
        <button v-if="false" type="button" class="btn btn-success btn-sm" v-b-modal.delIndicatorModal @click="delIndicator">supprimer</button>
    </h3>
    <div v-if="data.maj">MaJ : {{data.maj}}</div>

    <div style="display:none">Prévisions pour les jours à venir:
        <div v-for="(x, idx) in futurValues" :key="idx">{{x.date}} : {{x.eval_min}}</div>
    </div>

    <graph-2d
      ref="graph2d"
      :items="graph2d.items"
      :groups="graph2d.groups"
      :options="graph2d.options"
      @click="graph2dEvent('click')"
      >
    </graph-2d>

    <br/>
    <h4>Cumul des prédictions</h4>

    <graph-2d
      ref="histo"
      :items="histo.items"
      :groups="histo.groups"
      :options="histo.options"
      >
    </graph-2d>

    <!-- =========================================================================================== -->

    <b-modal ref="updIndicatorModal" id="updIndicatorModal" :title="'indicateur \''+data.name+'\''" hide-footer>
      <b-form @submit="onSubmitupdIndicator" @reset="onReset" class="w-100">

        <div>Collez vos données dans le bloc 'Valeurs'.</div>
        <div>Chaque ligne correspond à la dates indiquée dans le bloc 'dates'.</div>

          <b-button @click="year(2020)" ref='year2020' class='year 2020' variant="info">2020</b-button>
          <b-button @click="year(2021)" ref='year2021' class='year 2021' variant="info">2021</b-button>
          <b-button @click="year(2022)" ref='year2022' class='year 2022' variant="info">2022</b-button>
          <b-button @click="year(2023)" ref='year2023' class='year 2023' variant="info">2023</b-button>

     <table>
      <tr><td style="width: 200px">
          <b-form-group id="form-indicator-group0" label="Dates:" label-for="form-indicator-dates">
              <b-form-textarea id="form-indicator-dates" class="linked" @scroll="scrolling" rows="7" v-model="updIndicatorForm.dates" readonly placeholder="dates"/>
          </b-form-group>
        </td><td style="width: 280px">
          <b-form-group id="form-indicator-group" label="Valeurs:" label-for="form-indicator-values">
              <b-form-textarea id="form-indicator-values" class="linked" @scroll="scrolling" rows="7" v-model="updIndicatorForm.data" required placeholder="valeurs"/>
          </b-form-group>
      </td></tr>
     </table>

        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>



  </div>
</template>

<script>
//import * as d3 from 'd3'
import { Graph2d } from 'vue-visjs';
import AppliDS from "../services/AppliDS";

export default {
  props: ['data'],
  watch: {
    data: function(){this.loadData()},
  },
  data() {
        return {
          datas: [],
          loaded: {},
          accuracy: '',
          updIndicatorForm : {date:new Date().toISOString().substr(0, 10)},
          daterange: '?',
          bref: 'year2023',
          month: {'2020': [31,29,31,30,31,30,31,31,30,31,30,31],
                  '2021': [31,28,31,30,31,30,31,31,30,31,30,31],
                  '2022': [31,28,31,30,31,30,31,31,30,31,30,31],
                  '2023': [31,28,31,30,31,30,31,31,30,31,30,31],
          },
          graph2d: {
                groups: [
                  {id:'val', content:'Valeur ', options:{drawPoints:{style:'square', size:6}}},
                  {id:'pred', content:'Prédiction', options:{drawPoints:{style:'circle'}}},
                ],
                items: [
                ],
                options: {
                  //defaultGroup: 'ungrouped',
                  legend: true,
                  interpolation: {parametrization: 'centripetal'},
                  locale: 'fr',
                  start: '2023-01-01',
                  end: '2023-06-01',
                },
              },
          histo: {
                groups: [
                  {id:'val', content:'Valeur ', options:{drawPoints:{style:'square', size:6}}},
                  {id:'pred', content:'Prédiction', options:{drawPoints:{style:'circle'}}},
                ],
                items: [
                ],
                options: {
                  style:'bar',
                  drawPoints: false,
                  //legend: true,
                  //interpolation: {parametrization: 'centripetal'},
                  locale: 'fr',
                  start: '2020-01-01',
                  end: '2023-12-31',
                },
              },
        };
      },
  computed: {
    futurValues() {
        return this.datas.slice(-15);
    }
  },
  components: {
      Graph2d,
  },
  methods: {
    graph2dEvent(x) {
        console.log(x);
    },
    scrolling(evt) {
        let target=evt.target
        let id=target.id
        let id2 = id=='form-indicator-dates' ? 'form-indicator-values' : 'form-indicator-dates';
        let target2 = document.getElementById(id2); //this.$refs[id2]
        //console.log('SCROLL', target.scrollTop, target.scrollHeight, id, id2, target2); // evt.target.scrollTop
        target2.scrollTop = target.scrollTop;
    },
    year(y) {
        console.log('YEAR', y, 'bref='+this.bref+':', this.bref!='')
        console.log('$refs', this.$refs)
        let x=this.$refs[this.bref]
        console.log('x', x)
        if (x) x.removeAttribute('disabled', true);
        this.bref='year'+y
        x=this.$refs[this.bref]
        console.log('bref='+this.bref+':', this.bref!='', x)
        console.log(x)
        if (x) x.setAttribute('disabled', true);

        //alert(y+': '+this.loaded[y]);
        let dt=y+'-01-01', dates=''
        let days=this.month[y];
        console.log('DAYS', days)
        for(let m=1; m<13; m++) for(let j=1; j<=days[m-1]; j++) {
            dt=y+'-'+(m<10?'0':'')+m+'-'+(j<10?'0':'')+j
            dates+=dt+'\n';
        }
        this.updIndicatorForm.dates = dates
        this.updIndicatorForm.data = this.loaded[y].split(',').join('\n')
    },
    onReset(evt) {
        evt.preventDefault();
        this.updIndicatorForm={};
    },
    updIndicator() {
        this.$refs.updIndicatorModal.show(); // Evite d'ouvrir plusieurs fenetres !
        this.year(2023);
    },
    delIndicator() {
        if (confirm("Supprimer '"+this.data.name+"'")) {
          AppliDS.deleteIndicator(this.data.id)
                  .then((res) => {
                    this.datas = res.data;
                    this.$emit('update');
                    this.$emit('addMessage', res.data);
                  })
        }
    },
    onSubmitupdIndicator(evt) {
        evt.preventDefault();
        this.$refs.updIndicatorModal.hide();
        console.log(this.updIndicatorForm);
        AppliDS.saveIndicator(this.data.id, this.updIndicatorForm)
                  .then((res) => {
                    console.log(res.data);
                    this.loadData();
                    this.$emit('addMessage', res.data);
                  })
    },
    loadData() {
          //alert('load ', this.data.id);
          //console.log('Indicator load', data)
          console.log('Indicator load', this.data)
          AppliDS.getIndicator(this.data.id)
                  .then((res) => {
                    this.datas = res.data.values;
                    res.data.month.forEach(x=>{x.month=(x.month>9 ? "" : "0")+x.month;}); // Ajout '0' devant mois
                    this.loaded = res.data;
                    this.accuracy = res.data.accuracy;
                    let data='';
                    let now=new Date().toJSON();
                    let dates='';
                    let items=[];
                    res.data.values.forEach(x=>{
                        if (x.val!=null) items.push({'x':x.date, 'y':x.val, 'group':'val'});
                        //if (x.eval_min!=null) items.push({'x':x.date, 'y':x.eval_min, 'group':'pred'});
                        if (x.pred!=null) items.push({'x':x.date, 'y':x.pred, 'group':'pred'});
                        //console.log(x);
                        if (x.date<=now){
                            data+=(x.val ? x.val : '')+"\n";
                            dates+=x.date+"\n";
                            }
                        })
                    this.graph2d.items=items;

                    // histo
                    items=[];
                    res.data.month.forEach(x=>{
                        let dstart = new Date(x.year, x.month-1, 1); //x.year+'-'+x.month+'-01',
                        let dend = new Date(x.year, x.month-0, 0);//x.year+'-'+x.month+'-28',
                        items.push({'x': dstart,
                                    'end': dend,
                                    'y': x.pred_sum,
                                    'group':'pred'});
                        items.push({'x': dstart,
                                    'end': dend,
                                    'y': x.sum,
                                    'group':'val'});
                    });
                    console.log('HISTO', items);
                    this.histo.items=items;

                    this.updIndicatorForm={data:data, dates:dates}; //
                    console.log('CREATED', this.datas);
                  })
    },
  },
  created() {
  },
  mounted(){
    this.loadData();
    console.log('*************************************');
    console.log('papa', this.$papa);
      },

};


</script>
