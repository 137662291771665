<style scoped>
h1{
  margin-top: 130px;
}
</style>

<template>
  <div>
        <h1>{{ $t('newinscrip') }}</h1>
        <div class="pre">{{ $t('newinscriptxt') }}</div>
        <button v-if="!this.company" type="button" class="btn btn-success btn-sm" v-b-modal.addCompanyModal>{{ $t('newinscripadd') }}</button>
        <HowWork/>

    <b-modal ref="addCompanyModal" id="addCompanyModal" size="lg" title="Ajout Entreprise" hide-footer>
      <b-form @submit="onSubmitaddCompany" @reset="onReset" class="w-100">
      <b-form-group id="form-user-group" label="Company:" label-for="form-company-input">
          <b-form-input id="form-company-input" type="text" v-model="addCompanyForm.q" required @input="updSiren" placeholder="SIREN/nom"/>
      </b-form-group>
      <b-form-group id="form-user-group2" label="Choix:" label-for="form-company-choix">
          <b-form-select id="form-company-choix" v-model="addCompanyForm.siren" :options="addCompanyForm.options" required size="sm" class="mt-3"/>
      </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import HowWork from '@/components/HowWork.vue'
import AppliDS from "../services/AppliDS";

export default {
  name: 'NoCompanyPage',
  data() {
      return {
        company: null,
        addCompanyForm: {q:'',
                        siren: null,
                        options:[{ value: 'a', text: 'This is First option' }]},
      };
    },
  components: {
    HowWork,
  },
  methods: {
    updSiren() {
            if (this.addCompanyForm.q.length>4) {
                AppliDS.listCompany(this.addCompanyForm.q)
                    .then((res) => {
                        this.addCompanyForm.options=res.data;
                    })
            }
    },
    onSubmitaddCompany(evt) {
        evt.preventDefault();
        this.$refs.addCompanyModal.hide();
        console.log(this.addCompanyForm);
        AppliDS.updateCompany(this.addCompanyForm)
                .then((res) => {
                    console.log(res.data);
                    this.$emit('reload');
                })
    },
    onReset(evt) {
        evt.preventDefault();
        this.addCompanyForm={q:'', siren:'', options:[]};
    },
  },
  mounted() {
    //this.$emit('alert', 'secondary', ' No company !');
  },
};

</script>