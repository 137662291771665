import axios from 'axios';

class LoginDS {
  get() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/login');
  }

  create(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/login', data);
  }

  update(data) {
    return axios.patch(process.env.VUE_APP_ROOT_API+'/login', data)
  }

   //--------------------------------------
  logout() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/logout');
  }

  register(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/register', data);
  }

  // TODO needed ?
  /*
  validate(email, pass) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/register', data);
  }
  */

}

export default new LoginDS();
