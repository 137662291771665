<template>
  <div class="swiper-container">
    <div class="mySwiper swiper-h">
      <div class="slides">
        <div v-for="(slide, index) in slides" :key="index" :class="{ 'active': currentSlide === index }" class="swiper-slide">
          <img :src="slide.image" :alt="slide.title" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="controls-container">
      <div class="controls">
        <span v-for="(slide, index) in slides" :key="index" @click="goToSlide(index)" :class="{ 'active': currentSlide === index }" class="control"></span>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "MySwiper",
  data() {
    return {
      currentSlide: 0,
      slides: [
        { image: "prevision7j.png", title: "7 jours" },
        { image: "prevision6m.png", title: "6 mois" },
        { image: "timeline.png", title: "Calendrier" },
      ],
    };
  },
  mounted() {
    setInterval(this.nextSlide, 3000); // Changez cette valeur pour ajuster la durée de chaque diapositive
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
  },
};
</script>

<style scoped>
.img-fluid {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.swiper-container {

  overflow: visible;
}

.swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.swiper-h {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: "transparent";

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  opacity: 0;
}

.swiper-slide.active {
  opacity: 1;
}

.controls-container {
  position: absolute;
  bottom: -30px; /* Ajustez cette valeur pour positionner les contrôles */
  width: 78%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.controls {
  display: flex;
}

.control {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.control.active {
  background-color: #333;
}

@media (max-width: 991px) {


  .computer-container .mySwiper {

    left: 6%; /* Ajustez cette valeur pour déplacer le swiper horizontalement */
    width: 88%; /* Ajustez cette valeur pour modifier la largeur du swiper */

  }
  .controls-container {
    position: absolute;
    bottom: -30px;
    width: 49%;
    display: flex;
    justify-content: center;
    z-index: 1;
}

@media (max-width: 768px) {
  .controls-container {
    position: absolute;
    bottom: -30px;
    width: 67%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
}

@media (max-width: 575px) {

  .controls-container{
    position: absolute;
    bottom: -30px;
    width: 76%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
}

}
</style>
