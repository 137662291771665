

<template>
  <div id="app">

      <div id="alert-container" class="position-fixed bottom-0 end-0 p-3" style="z-index:1">
          <b-alert v-for="(alert,ia) in alerts" :key="ia" :variant="alert.variant" show=3>{{alert.msg}}</b-alert>
      </div>


  <!-- ======= Header ======= -->
<header id="header" class="fixed-top" >
    <div class="container d-flex align-items-center">

      <div class="dropdown-language">
        <button class="btn btn-outline-light dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <img :src="$i18n.locale + '.png'" alt="Language" class="flag-icon">
        </button>
        <ul class="dropdown-menu" aria-labelledby="languageDropdown">
          <li>
            <a class="dropdown-item" href="#" @click.prevent="changeLanguage('fr')">
              <img src="fr.png" alt="Français" class="flag-icon">
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="changeLanguage('en')">
              <img src="en.png" alt="English" class="flag-icon">
            </a>
          </li>

        </ul>
      </div>

        <!-- Logo -->
        <div class="logo me-auto"> <a href="/"><img src="logo-bizzo.png" alt="bizzo"></a></div> 
     
        <!-- Add this dropdown menu to your navbar -->

        <nav id="navbar" class="navbar">
          <ul v-if="!login">
            <li><a class="nav-link scrollto " href="#about" @click="setActive($event); $router.push('/#about')">{{ $t('comment') }}</a></li>
            <li><a class="nav-link scrollto" href="#services" @click="setActive($event); $router.push('/#services')">{{ $t('pourquoi') }}</a></li>
            <li><a class="nav-link scrollto" href="#skills" @click="setActive($event); $router.push('/#skills')">{{ $t('qui') }}</a></li>
            <li><a class="nav-link scrollto" href="#pricing" @click="setActive($event); $router.push('/#pricing')">{{ $t('combien') }}</a></li>
            <li><a class="nav-link scrollto" href="#team" @click="setActive($event); $router.push('/#team')">{{ $t('contact') }}</a></li>
            <li><a class="nav-link" href="#" @click="setActive($event); $router.push('/blog-business-intelligence-appli-Val-Loire-pme')">{{ $t('blog') }}</a></li>
            <li><a href="#" class="nav-link" @click="setActive($event); $router.push('/cgv');">{{ $t('cgv') }}</a></li>
            <li><a href="#" class="nav-link" @click="setActive($event); $router.push('/mentions');">{{ $t('mentions') }}</a></li>
            <button type="button" class="navbar btn btn-outline-light getstarted scrollto register" v-b-modal.registerModal>{{ $t('register2') }}</button>
            <button type="button" class="navbar btn btn-outline-light getstarted scrollto login" v-b-modal.loginModal>{{ $t('login') }}</button>
            <!-- Add this button to your navbar -->
          </ul>
          <ul v-else style=""> <!-- display: flex; list-style: none; -->
            <li><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/app');">{{ $t('app') }}</a></li>
            <li><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/graph');">{{ $t('graph') }}</a></li>
            <li><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/timeline');">{{ $t('timeline') }}</a></li>
            <li><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/marketing');">{{ $t('marketing') }}</a></li>
            <li><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/blog-business-intelligence-appli-Val-Loire-pme')">{{ $t('blog') }}</a></li>
            <li v-if="login.role & 1"><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/facebook');">{{ $t('facebook') }}</a></li>
            <li v-if="login.role & 1"><a href="#" class="nav-link scrollto" @click="setActive($event); $router.push('/admin');">{{ $t('admin') }}</a></li>
            <button type="button" class="navbar btn btn-outline-light getstarted scrollto logout" @click="onSubmitLogout">{{ $t('logout') }}</button>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->


          
          
    </div>
</header><!-- End Header -->

    <router-view :login="login" @alert="onAlert"/>

    <b-modal ref="loginModal"
            id="loginModal"
            title="Login"
            hide-footer>
      <b-form @submit="onSubmitLogin" @reset="onReset" class="w-100">
      <b-form-group id="form-user-group"
                    label="Email:"
                    label-for="form-user-input">
          <b-form-input id="form-user-input"
                        type="text"
                        v-model="loginForm.email"
                        required
                        placeholder="Enter User">
          </b-form-input>
        </b-form-group>
      <b-form-group id="form-password-group"
                    label="Password:"
                    label-for="form-password-input">
          <b-form-input id="form-password-input"
                        type="password"
                        v-model="loginForm.password"
                        required
                        placeholder="Enter password">
          </b-form-input>
        </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>

    <b-modal ref="registerModal"
            id="registerModal"
            title="Register"
            hide-footer>
      <b-form @submit="onSubmitRegister" @reset="onReset" class="w-100">
      <b-form-group id="form-email-group" label="Email:" label-for="form-email-input">
          <b-form-input id="form-email-input" type="text" v-model="registerForm.email" required placeholder="Enter Email">
          </b-form-input>
        </b-form-group>
      <b-form-group id="form-password-group" label="Password:" label-for="form-password-input">
          <b-form-input id="form-password-input" type="password" v-model="registerForm.password" required placeholder="Enter password">
          </b-form-input>
        </b-form-group>
      <b-form-group id="form-password2-group" label="Password(confirm):" label-for="form-password2-input">
          <b-form-input id="form-password2-input" type="password" v-model="registerForm.password2" required placeholder="Confirm password">
          </b-form-input>
        </b-form-group>
        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>


  </div>
</template>



<script>
//import Company from '@/components/Company.vue'
import LoginDS from "./services/LoginDS";

export default {
  data() {
      return {
        loginForm: {},
        registerForm: {},
        showMessage: false,
        login: null,
        isMobileMenuOpen: false,
        alerts: [], // {msg: 'OLD Bizzo à votre service', variant:'primary'}
      };
    },
  components: {
    //Company
  },
  methods: {
      onAlert(variant, message) {
          this.alerts.push({msg: message, variant:variant})
      },
      initForm() {
            this.loginForm = {};
            this.registerForm = {};
          },
      onSubmitLogout(evt) {
            evt.preventDefault();
            LoginDS.logout()
                .then((res) => {
                    console.log(res.data);
                    this.login = null;
                    this.$router.push({ path: '/', replace: true })
                    this.onAlert('danger', 'Vous êtes déconnecté')
                })
          },
      onSubmitLogin(evt) {
        //alert('onSubmitRegister');
        //console.log('onSubmitLogin')
            evt.preventDefault();
            this.$refs.loginModal.hide();
            console.log(this.loginForm);
            LoginDS.create(this.loginForm)
                .then((res) => {
                    console.log(res.data);
                    this.login = res.data;
                    if (res.data) {
                        this.$router.push({ path: '/app', replace: true });
                        this.onAlert('danger', 'Vous êtes connecté');
                    } else {
                        this.onAlert('danger', 'Erreur de connection !');
                    }
                })
            this.initForm();
           },
      onSubmitRegister(evt) {
        //alert('onSubmitRegister');
            evt.preventDefault();
            this.$refs.registerModal.hide();
            //const payload = {user:this.registerForm.user, email:this.registerForm.email, password:this.registerForm.password, password2:this.registerForm.password2};
            LoginDS.register(this.registerForm)
                .then((res) => {
                    console.log(res.data);
                    this.login = null; //res.data;
                    console.log('register', res.data);
                    //alert(res.data); //('Vous pouvez vous connecter (bouton login)')
                    this.onAlert('danger', res.data); //'Vous pouvez vous connecter')
                })
            this.initForm();
      },
      onReset(evt) {
        console.log('onReset')
            evt.preventDefault();
            this.$refs.loginModal.hide();
            this.$refs.registerModal.hide();
            this.initForm();
      },
      mobileMenuToggle() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      },
      setActive(event) {
        const navbarLinks = document.querySelectorAll('.navbar a');
        navbarLinks.forEach(link => link.classList.remove('active'));
        event.target.classList.add('active');
      },
      changeLanguage(lang) {
        this.$i18n.locale = lang;
      },

  },
  mounted() {
    this.onAlert("info", this.$i18n.t('bizzoserv'));

    // Axeptio
    window.axeptioSettings = {
      clientId: "64ec6e88585d4d3ce2dc4f6d",
      cookiesVersion: "bizzo-fr", // TODO lang
    };
    (function(d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
      e.async = true; e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");

    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function(axeptio) {
      axeptio.on("cookies:complete", function(choices) {
        console.log('AXEPTIO App.vue', choices);
      })
    })

  },
  created() {
    LoginDS.get()
              .then((res) => {
                console.log(res.data);
                this.login = res.data;
              })
  },
  computed: {
  languageButtonText() {
    return this.$i18n.locale === 'en' ? 'Language' : 'Langue';
  },
},
};
</script>
<style scoped>

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
:root {
  --couleur-principale: #2aa75a;
  --couleur-secondaire:#7d4ffe ;
  --couleur-tertiaire: #96e8b5;
  --couleur-texte: #444444;
}
#navbarConnect{
  background: var(--couleur-principale);
  opacity: 0.9;
  padding: 0;
  margin: 0;

  z-index: 999;
}
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}
.fixed-top {
  background: var(--couleur-principale) !important;
  opacity: 0.9 !important;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: var(--couleur-principale);
  opacity: 0.9;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

.flag-icon{
  width: 20px;
  height: 14px;
  margin-right: 5px;
}

.dropdown-language {
  position: absolute;
  left: -260px;
  top: 50%;
  transform: translateY(-50%);
}

.container.d-flex.align-items-center {
  position: relative;
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fff;
}

.navbar a::before,
.navbar a:focus::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 30px; /* Ajustement de la position gauche du soulignement */
  background-color: #fff;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.navbar a:hover::before,
.navbar .active::before,
.navbar .active:focus::before,
.navbar li:hover>a::before {
  visibility: visible;
  width: calc(100% - 30px); /* Ajustement de la largeur du soulignement pour correspondre à la position gauche */
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #f0f3f4;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: var(--couleur-principale);
  background: #fdfdfd;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  //min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: var(--couleur-principale);
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color:var(--couleur-secondaire);
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}


@media (max-width: 1366px) {


  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {


  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: var(--couleur-principale);
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color:var(--couleur-secondaire);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: var(--couleur-principale);
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color:var(--couleur-secondaire);
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}







</style>