import axios from 'axios';

class AdminDS {
  getUsers() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/admin/users');
  }
  getEvents() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/admin/events');
  }
  getCompanies() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/admin/companies');
  }
}

export default new AdminDS();
